import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/page/Home.css";
import NavBar from "../component/shared/NavBar";
import NavigationBar from "../component/contentHome/NavigationBar";
import { Outlet } from "react-router-dom";

export default function Home() {
  function isTokenExpired(token) {
    const tokenParts = token !== null ? token.split(".") : 0; // Tách token thành các phần

    if (tokenParts.length !== 3) {
      // Token không hợp lệ
      console.log("token k hợp lệ");
      return true;
    }

    const payloadBase64 = tokenParts[1];
    const payload = atob(payloadBase64); // Giải mã phần payload

    try {
      const { exp } = JSON.parse(payload); // Truy cập trường exp trong payload

      const currentTimestamp = Math.floor(Date.now() / 1000); // Lấy thời gian hiện tại (đơn vị giây)

      if (exp < currentTimestamp) {
        localStorage.setItem("exp", exp);
        localStorage.setItem("currentTimestamp", currentTimestamp);
        return true;
      } else {
        // Token còn hiệu lực
        return false;
      }
    } catch (error) {
      console.error("Error parsing token payload:", error);
      return true;
    }
  }

  const encodedToken = localStorage.getItem("AuthorizationSunny");

  const checkTimeToken = () => {
    if (isTokenExpired(encodedToken)) {
      window.location.href = "/login";
    }
  };

  checkTimeToken();

  return (
    <div className="Home-Container">
      <NavBar />
      <div className="flex-div">
        <NavigationBar />
        <div className="Home-WrapContent col-md-12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
