import React, { useState } from "react";
import "../../assets/css/share/NavigationBar.css";
import {
  FaBars,
  FaRegNewspaper,
  FaRegListAlt,
  FaSignOutAlt,
  FaHome,
} from "react-icons/fa";
import IconUser from "../../assets/img/Icon/user_1.png";
import IconContent from "../../assets/img/Icon/Content.png";
import IconQuote from "../../assets/img/Icon/Quote.png";
import IconSetting from "../../assets/img/Icon/setting.png";
import { Link } from "react-router-dom";

export default function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleMiniMenu, setToggleMiniMenu] = useState(false);

  const handleSidebarToggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleSubMenu = () => {
    setToggleMiniMenu(!toggleMiniMenu);
  };

  const LogOut = () => {
    localStorage.removeItem("AuthorizationSunny");
    window.location.href = "/login";
  };
  return (
    <React.Fragment>
      <div className={`NavigationBarWrap ${isOpen ? "open" : ""}`}>
        {isOpen ? (
          <div className="Navigation-Hi">Xin chào!</div>
        ) : (
          <></>
        )}
        <div className="NavigationBarMenu">
          <div
            className="flex-div NavigationBarMenuWrap"
            onClick={handleSidebarToggle}
          >
            <div className="center-lmh NavigationBarMenu-titleList">
              Danh sách
            </div>
            <FaBars className="NavigationBarMenu-icon" />
          </div>
          {isOpen ? <div className="NavigationBarMenu-fadedLine"></div> : <></>}

          <Link
            to="/dashboard"
            className="Navigation-Link"
            onClick={() => {
              isOpen === true ? setIsOpen(false) : setIsOpen(false);
            }}
          >
            <div className="flex-div NavigationBarMenuWrap">
              <div className="center-lmh NavigationBarMenu-titleList">
                Trang chủ
              </div>
              <FaHome className="NavigationBarMenu-icon" style={{fontSize:'43px'}}/>
            </div>
            {isOpen ? (
              <div className="NavigationBarMenu-fadedLine"></div>
            ) : (
              <></>
            )}
          </Link>

          <Link
            to="/account"
            className="Navigation-Link"
            onClick={() => {
              isOpen === true ? setIsOpen(false) : setIsOpen(false);
            }}
          >
            <div className="flex-div NavigationBarMenuWrap">
              <div className="center-lmh NavigationBarMenu-titleList">
                Tài khoản
              </div>
              <img
                alt="icon"
                className="NavigationBarMenu-icon"
                src={IconUser}
              />
            </div>
            {isOpen ? (
              <div className="NavigationBarMenu-fadedLine"></div>
            ) : (
              <></>
            )}
          </Link>

          <Link
            to="/content-management"
            className="Navigation-Link"
            onClick={() => {
              isOpen === true ? setIsOpen(false) : setIsOpen(false);
            }}
          >
            <div className="flex-div NavigationBarMenuWrap">
              <div className="center-lmh NavigationBarMenu-titleList">
                Nội dung
              </div>
              <img
                alt="icon"
                className="NavigationBarMenu-icon"
                src={IconContent}
              />
            </div>
          </Link>
          {isOpen ? <div className="NavigationBarMenu-fadedLine"></div> : <></>}
          <Link
            to="/service-quote"
            className="Navigation-Link"
            onClick={() => {
              isOpen === true ? setIsOpen(false) : setIsOpen(false);
            }}
          >
            <div className="flex-div NavigationBarMenuWrap">
              <div className="center-lmh NavigationBarMenu-titleList">
                Báo giá
              </div>
              <img
                alt="icon"
                className="NavigationBarMenu-icon"
                src={IconQuote}
              />
            </div>
          </Link>
          {isOpen ? <div className="NavigationBarMenu-fadedLine"></div> : <></>}
          <div
            className="flex-div NavigationBarMenuWrap"
            onClick={toggleSubMenu}
          >
            <div className="center-lmh NavigationBarMenu-titleList">
              Thiết lập
            </div>
            <img
              alt="icon"
              className="NavigationBarMenu-icon"
              src={IconSetting}
            />
          </div>
          {isOpen ? <div className="NavigationBarMenu-fadedLine"></div> : <></>}
          {toggleMiniMenu ? (
            <Link
              to="/category"
              className="Navigation-Link"
              onClick={() => {
                isOpen === true ? setIsOpen(false) : setIsOpen(false);
              }}
            >
              <div className="flex-div NavigationBarMenuWrap">
                <div
                  className="center-lmh NavigationBarMenu-titleList"
                  style={{ fontSize: "20px", fontStyle: "italic" }}
                >
                  Danh mục
                </div>
                <FaRegNewspaper className="NavigationBarMenu-icon" />
              </div>
            </Link>
          ) : (
            <></>
          )}

          {isOpen ? <div className="NavigationBarMenu-fadedLine"></div> : <></>}
          {toggleMiniMenu ? (
            <Link
              to="/sub-category"
              className="Navigation-Link"
              onClick={() => {
                isOpen === true ? setIsOpen(false) : setIsOpen(false);
              }}
            >
              <div className="flex-div NavigationBarMenuWrap">
                <div
                  className="center-lmh NavigationBarMenu-titleList"
                  style={{ fontSize: "20px", fontStyle: "italic" }}
                >
                  Chi tiết <br /> danh mục
                </div>
                <FaRegListAlt className="NavigationBarMenu-icon" />
              </div>
            </Link>
          ) : (
            <></>
          )}

          {isOpen ? <div className="NavigationBarMenu-fadedLine"></div> : <></>}
          <div className="flex-div NavigationBarMenuWrap" onClick={LogOut}>
            <div className="center-lmh NavigationBarMenu-titleList">
              Đăng xuất
            </div>
            <FaSignOutAlt className="NavigationBarMenu-icon" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
