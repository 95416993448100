import { Form } from "react-bootstrap";

function ItemsPerPageSelect(props) {
  const handleChange = (event) => {
    props.onSelect(parseInt(event.target.value));
  };

  return (
    <div className="itemsPerPage center-lmh-1">
      <Form.Select onChange={handleChange} value={props.value} className="me-3">
        {/* <option value="2">2</option> */}
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
      </Form.Select>
    </div>
  );
}

export default ItemsPerPageSelect;
