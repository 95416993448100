import React, { useEffect, useState } from "react";
import { FaSearch, FaPencilAlt } from "react-icons/fa";
import "../../assets/css/contentHome/Category.css";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Form as FormSelect } from "react-bootstrap";
import axios, { setAuthToken } from "../../config/axiosConfig";
import Swal from "sweetalert2";

export default function Category() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));
  const [inputValue, setInputValue] = useState("");
  //phân trang==============================================================
  // const [totalPages, setTotalPages] = useState(1); // tổng số page
  const [dataStatus, setDataStatus] = useState(""); //trang thai status
  const [dataCategory, setDataCategory] = useState();

  const searchData = (inputValue) => {
    setInputValue(inputValue);
    axios
      .post("sunnyAdmin/v1/category/search", {
        keyword: inputValue,
        status: dataStatus,
      })
      .then((response) => {
        setDataCategory(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchData(event.target.value);
    }
  };

  const handleSelectStatus = (event) => {
    setDataStatus(event.target.value);
    axios
      .post("sunnyAdmin/v1/category/search", {
        keyword: inputValue,
        status: event.target.value,
      })
      .then((response) => {
        setDataCategory(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const onPageChange = () => {};
  // const handleItemsPerPageSelect = () => {};

  const [showModalAdd, setShowModalAdd] = useState(false);
  const handleCloseAdd = () => setShowModalAdd(false);
  // const handleShowAdd = () => {
  //   setShowModalAdd(true);
  // };

  //--------------- modal add account------------------------------------
  const [infoCategoryAdd] = useState({
    categoryNameVn: "",
    categoryNameEn: "",
    description: "",
    status: true,
  });
  const initialValuesAdd = {
    categoryNameVn: infoCategoryAdd.categoryNameVn,
    categoryNameEn: infoCategoryAdd.categoryNameEn,
    description: infoCategoryAdd.description,
    status: infoCategoryAdd.status,
  };
  const validationSchemaAdd = Yup.object().shape({
    categoryNameVn: Yup.string().required("Vui lòng nhập tên danh mục"),
    categoryNameEn: Yup.string().required("Vui lòng nhập tên danh mục"),
    description: Yup.string(),
    status: Yup.boolean(),
  });
  // ---------------------------------------------------------------------------------

  //--------------- modal edit account------------------------------------
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [infoCategoryEdit, setInfoCategoryEdit] = useState({
    categoryId: "",
    categoryNameVn: "",
    categoryNameEn: "",
    description: "",
    status: false,
  });
  const handleCloseEdit = () => setShowModalEdit(false);
  const handleShowEdit = async (idCategory) => {
    const dataU = await axios
      .post("sunnyAdmin/v1/category/searchById", {
        keyword: idCategory,
      })
      .then((response) => {
        setInfoCategoryEdit({
          categoryId: idCategory,
          categoryNameVn: response.data.categoryVn,
          categoryNameEn: response.data.categoryEn,
          description: response.data.description,
          status: response.data.status === "ACTIVE" ? true : false,
        });
      })
      .then(() => {
        setShowModalEdit(true);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
        });
      });
    console.log(dataU);
  };

  const initialValuesEdit = {
    categoryId: infoCategoryEdit.categoryId,
    categoryNameVn: infoCategoryEdit.categoryNameVn,
    categoryNameEn: infoCategoryEdit.categoryNameEn,
    description: infoCategoryEdit.description,
    status: infoCategoryEdit.status,
  };
  const validationSchemaEdit = Yup.object().shape({
    categoryId: Yup.string().required("Không tìm thấy id danh mục"),
    categoryNameVn: Yup.string().required("Vui lòng nhập tên danh mục"),
    categoryNameEn: Yup.string().required("Vui lòng nhập tên danh mục"),
    description: Yup.string().required("Vui lòng nhập  mô tả"),
    status: Yup.boolean(),
  });
  // ---------------------------------------------------------------------------------

  const test = () => {
    console.log(dataStatus);
  };

  useEffect(() => {
    axios
      .post("sunnyAdmin/v1/category/list", {})
      .then((response) => {
        setDataCategory(response.data.response);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="home-contentPage-title" onClick={test}>
        Quản lý danh mục
      </div>
      <div className="contentM-option flex-div col-md-12">
        <div className="flex-div">
          {/* <div className="contentM-wrapBtnAdd">
            <button className="account-btnAdd" onClick={handleShowAdd}>
              Thêm mới
            </button>
          </div> */}
          <div className="contentM-wrapBtnAdd">
            <FormSelect.Select
              onChange={handleSelectStatus}
              className="account-wrapSelectStatus font18"
            >
              <option value="">Tất cả trạng thái</option>
              <option value="ACTIVE">Sử dụng</option>
              <option value="INACTIVE">Không sử dụng</option>
            </FormSelect.Select>
          </div>
        </div>
        <div className="contentM-navbarRight">
          <div className="account-searchAddContainer center-lmh">
            <input
              type="text"
              placeholder="Tìm kiếm"
              name="search"
              className="account-inputSearch"
              onKeyDown={handleKeyPress}
              onChange={(event) => setInputValue(event.target.value)}
            />
            <button
              className="account-btnSearch"
              onClick={() => searchData(inputValue)}
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
      <div className="contentM-WrapTable table-responsive">
        <Table hover className="contentM-Table">
          <thead className="table-thead">
            <tr>
              <th style={{textAlign:'center'}}>STT</th>
              <th>Tên Tiếng Việt</th>
              <th>Tên Tiếng Anh</th>
              <th>Mô tả</th>
              <th>Trạng thái</th>
              <th className="text-center">Tác vụ</th>
            </tr>
          </thead>
          <tbody className="table-tbody">
            {dataCategory ? (
              dataCategory.map((value, key) => (
                <tr key={key}>
                  <td style={{ width: "4%", textAlign:'center' }}>{key + 1}</td>
                  <td style={{ width: "20%" }}>{value.categoryVn}</td>
                  <td style={{ width: "20%" }}>{value.categoryEn}</td>
                  <td style={{ width: "30%" }}>{value.description}</td>
                  <td style={{ width: "16%" }}>
                    {value.status === "ACTIVE" ? "Sử dụng" : "Không sử dụng"}
                  </td>
                  <td style={{ width: "10%" }} className="text-center">
                    <FaPencilAlt
                      className="account-EditIcon"
                      onClick={() => handleShowEdit(value.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>

      {/* ================================== Modal add================================== */}
      <Modal show={showModalAdd} onHide={handleCloseAdd} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Thêm mới danh mục</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValuesAdd}
            validationSchema={validationSchemaAdd}
            onSubmit={(values) => {
              const infoAdd = {
                categoryNameVn: values.categoryNameVn,
                categoryNameEn: values.categoryNameEn,
                description: values.description,
                status: values.status === true ? "ACTIVE" : "INACTIVE",
              };
              const bodyUpdate = JSON.stringify(infoAdd);
              axios
                .post("sunnyAdmin/v1/category/create", bodyUpdate)
                .then((response) => {
                  if (response.data.status === "SUCCESS") {
                    Swal.fire({
                      title: "Thành công!",
                      text: "Thêm danh mục thành công",
                      icon: "success",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleCloseAdd();
                        // Thực thi hàm khi nhấn OK
                        axios
                          .post("sunnyAdmin/v1/category/list", {})
                          .then((response) => {
                            setDataCategory(response.data.response);
                            console.log(response.data);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    });
                  } else {
                    Swal.fire({
                      title: "Thất bại!",
                      text: "Tên danh mục đã tồn tại!",
                      icon: "error",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // Thực thi hàm khi nhấn OK
                        axios
                          .post("sunnyAdmin/v1/category/list", {})
                          .then((response) => {
                            setDataCategory(response.data.response);
                            console.log(response.data);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    });
                  }
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            {({ values }) => (
              <Form>
                <div className="category-wrapItemAdd-T">
                  <div className="category-ItemAddTitle">Tên Tiếng Việt: </div>
                  <div className="category-wrapInput">
                    <Field
                      type="text"
                      name="categoryNameVn"
                      className="category-AddInput"
                      id="categoryNameVn"
                      value={values.categoryNameVn}
                    />
                    <ErrorMessage
                      name="categoryNameVn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Tên Tiếng Anh: </div>
                  <div className="category-wrapInput">
                    <Field
                      type="text"
                      name="categoryNameEn"
                      className="category-AddInput"
                      id="categoryNameEn"
                      value={values.categoryNameEn}
                    />
                    <ErrorMessage
                      name="categoryNameEn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Mô tả: </div>
                  <div className="category-wrapInput">
                    <Field
                      type="text"
                      name="description"
                      className="category-AddInput"
                      id="description"
                      value={values.description}
                    />
                    <ErrorMessage
                      name="description"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd-Check flex-div">
                  <div className="category-ItemAddTitle">Sử dụng: </div>
                  <div className="category-wrapCheckbox flex-div">
                    <Field
                      type="checkbox"
                      name="status"
                      className="category-checkbox"
                    />
                    <ErrorMessage
                      name="status"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>

                <div className="category-filter-Wrapbtn">
                  <button
                    type="submit"
                    className="category-btnAccept btn btn-success"
                  >
                    Thêm danh mục
                  </button>
                  <button
                    onClick={handleCloseAdd}
                    className="category-btnCancel btn"
                  >
                    Huỷ
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ================================== Modal edit================================== */}
      <Modal show={showModalEdit} onHide={handleCloseEdit} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa danh mục</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValuesEdit}
            validationSchema={validationSchemaEdit}
            onSubmit={(values) => {
              const infoAdd = {
                categoryId: values.categoryId,
                categoryNameVn: values.categoryNameVn,
                categoryNameEn: values.categoryNameEn,
                description: values.description,
                status: values.status === true ? "ACTIVE" : "INACTIVE",
              };
              const bodyUpdate = JSON.stringify(infoAdd);
              axios
                .post("sunnyAdmin/v1/category/update", bodyUpdate)
                .then((response) => {
                  if (response.data.status === "SUCCESS") {
                    Swal.fire({
                      title: "Thành công!",
                      text: "Chỉnh sửa danh mục thành công",
                      icon: "success",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleCloseEdit();
                        // Thực thi hàm khi nhấn OK
                        axios
                          .post("sunnyAdmin/v1/category/list", {})
                          .then((response) => {
                            setDataCategory(response.data.response);
                            console.log(response.data);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    });
                  } else if (response.data.status === "INVALID") {
                    Swal.fire({
                      title: "Thất bại!",
                      text: "Tên danh mục đã tồn tại!",
                      icon: "error",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // Thực thi hàm khi nhấn OK
                        axios
                          .post("sunnyAdmin/v1/category/list", {})
                          .then((response) => {
                            setDataCategory(response.data.response);
                            console.log(response.data);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    });
                  }
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            {({ values }) => (
              <Form>
                <div className="category-wrapItemAdd-T">
                  <div className="category-ItemAddTitle">Tên Tiếng Việt: </div>
                  <div className="">
                    <Field
                      type="text"
                      name="categoryNameVn"
                      className="category-AddInput"
                      id="categoryNameVn"
                      value={values.categoryNameVn}
                    />
                    <ErrorMessage
                      name="categoryNameVn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Tên Tiếng Anh: </div>
                  <div className="">
                    <Field
                      type="text"
                      name="categoryNameEn"
                      className="category-AddInput"
                      id="categoryNameEn"
                      value={values.categoryNameEn}
                    />
                    <ErrorMessage
                      name="categoryNameEn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Mô tả: </div>
                  <div className="">
                    <Field
                      type="text"
                      name="description"
                      className="category-AddInput"
                      id="description"
                      value={values.description}
                    />
                    <ErrorMessage
                      name="description"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Trạng thái: </div>
                  <div className="">
                    <Field
                      type="checkbox"
                      name="status"
                      className="category-checkbox"
                    />
                    <ErrorMessage
                      name="status"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>

                <div className="category-filter-Wrapbtn">
                  <button
                    type="submit"
                    className="category-btnAccept btn btn-success"
                  >
                    Cập nhật
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseEdit}
                    className="category-btnCancel btn"
                  >
                    Huỷ
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
