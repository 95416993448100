import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import "../../assets/css/contentHome/ServiceQuote.css";
import Table from "react-bootstrap/Table";
import Pagination from "../shared/Pagination";
import ItemsPerPageSelect from "../shared/ItemsPerPageSelect";
import axios, { setAuthToken } from "../../config/axiosConfig";
import Swal from "sweetalert2";
import ExportExcel from "../shared/ExportExcel";

export default function ServiceQuote() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));
  const [inputValue, setInputValue] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const dataExcel = [];

  //phân trang==============================================================
  const [currentPage, setCurrentPage] = useState(1); //page hiện tại
  const [itemsPerPage, setItemsPerPage] = useState(10); //số lượng phần tử trong một trang
  const [totalPages, setTotalPages] = useState(1); // tổng số page
  const [dataCost, setDataCost] = useState();

  // const fitlerExcel = (data) => {
  //   const custom = {
  //     Ten: "",
  //     SoDienThoai: "",
  //     email: "",
  //   };
  //   data.forEach((element) => {
  //     custom.Ten = element.name;
  //     custom.SoDienThoai = element.phoneNumber
  //     console.log(custom);
  //     dataExcel.concat(custom);
  //   });
  // };
  const fitlerExcel = (data) => {
    data.map(({ name }) => ({ Ten: name }));
  };

  // thực hiện ấn vào nút search---------------------------------------
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchData(event.target.value);
    }
  };
  const searchData = (inputValue) => {
    axios
      .post("sunnyAdmin/v1/cost/search", {
        startDate: dateStart,
        endDate: dateEnd,
        keyword: inputValue,
        status: "",
        pageSize: itemsPerPage,
        pageNumber: currentPage,
      })
      .then((response) => {
        setDataCost(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //format về năm tháng ngày----------------------------------------------------
  const formatDate = (inputDate) => {
    const dateObj = new Date(inputDate);
    const year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let day = dateObj.getDate();
    day = day < 10 ? `0${day}` : day;
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  //format về ngày tháng năm giờ phút----------------------------------------------------
  const formatDateH = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`; //padStart() để thêm số 0 vào trước giá trị của các thành phần ngày giờ
    return formattedDate;
  };

  function getDay() {
    //lấy ngày hiện tại
    const today = new Date();
    const formattedDate = formatDate(today);
    setDateStart(formattedDate);
    setDateEnd(formattedDate);
  }

  // sự kiện khi thay đổi giá trị ngày bắt đầu
  const handleChangeDateStart = (e) => {
    const inputDate = e.target.value;
    const formattedDate = formatDate(inputDate);
    if (e.target.value > dateEnd) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ngày bắt đầu không được lớn hơn ngày kết thúc!",
      });
    } else {
      setDateStart(formattedDate);
    }

    if (e.target.value === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Không được để trống ngày bắt đầu!",
      });
      const today = new Date();
      const formattedDate = formatDate(today);
      setDateStart(formattedDate);
      setDateEnd(formattedDate);
    }
    axios
      .post("sunnyAdmin/v1/cost/search", {
        startDate: e.target.value,
        endDate: dateEnd,
        keyword: inputValue,
        status: "",
        pageSize: itemsPerPage,
        pageNumber: currentPage,
      })
      .then((response) => {
        setDataCost(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // sự kiện khi thay đổi giá trị ngày kết thúc
  const handleChangeDateEnd = (e) => {
    const inputDate = e.target.value;
    const formattedDate = formatDate(inputDate);
    if (e.target.value < dateStart) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ngày kết thúc không được nhỏ hơn ngày bắt đầu!",
      });
    } else {
      setDateEnd(formattedDate);
    }
    axios
      .post("sunnyAdmin/v1/cost/search", {
        startDate: dateStart,
        endDate: e.target.value,
        keyword: inputValue,
        status: "",
        pageSize: itemsPerPage,
        pageNumber: currentPage,
      })
      .then((response) => {
        setDataCost(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    axios
      .post("sunnyAdmin/v1/cost/search", {
        startDate: dateStart,
        endDate: dateEnd,
        keyword: inputValue,
        status: "",
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
      })
      .then((response) => {
        setDataCost(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleItemsPerPageSelect = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/cost/search", {
        startDate: dateStart,
        endDate: dateEnd,
        keyword: inputValue,
        status: "",
        pageSize: value,
        pageNumber: 1,
      })
      .then((response) => {
        setDataCost(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    async function fetchData() {
      getDay();
    }
    fetchData();

    // axios
    //   .post("sunnyAdmin/v1/cost/list", {
    //     pageNumber: currentPage,
    //     pageSize: itemsPerPage,
    //   })
    //   .then((response) => {
    //     setDataCost(response.data.response);
    //     setTotalPages(response.data.totalPages);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    axios
      .post("sunnyAdmin/v1/cost/search", {
        startDate: dateStart,
        endDate: dateEnd,
        keyword: inputValue,
        status: "",
        pageSize: itemsPerPage,
        pageNumber: currentPage,
      })
      .then((response) => {
        setDataCost(response.data.response);
        setTotalPages(response.data.totalPages);
        fitlerExcel(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const test = () => {
    console.log("excel: ", dataExcel);
    console.log("aa: ", dataCost);
    // console.log("length: ", dataCost.length);
  };

  return (
    <div>
      <div className="home-contentPage-title" onClick={test}>
        Quản lý khách hàng
      </div>
      <div className="contentM-option flex-div col-md-12">
        <div className="serviceQuote-wrapBtnExport">
          <ExportExcel
            data={dataCost}
            // headers={headers}
            title="Thông tin khách hàng"
          />
        </div>
        <div className="contentM-navbarRight">
          <div className="flex-div center-lmh">
            <div className="serviceQuote-WrapDateStart">
              <div className="serviceQuote-titleDate center-lmh">Từ ngày: </div>
              <input
                type="date"
                value={dateStart}
                className="serviceQuote-inputDate"
                onChange={handleChangeDateStart}
              />
            </div>
            <div className="serviceQuote-WrapDateEnd">
              <div className="serviceQuote-titleDate center-lmh">Đến ngày:</div>
              <input
                type="date"
                value={dateEnd}
                className="serviceQuote-inputDate"
                onChange={handleChangeDateEnd}
              />
            </div>
          </div>
          <div className="serviceQuote-searchAddContainer">
            <input
              type="text"
              placeholder="Tìm kiếm"
              name="search"
              className="serviceQuote-inputSearch"
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyPress}
            />
            <button
              className="serviceQuote-btnSearch"
              onClick={() => searchData(inputValue)}
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
      <div className="contentM-WrapTable table-responsive">
        <Table hover className="contentM-Table">
          <thead className="table-thead">
            <tr>
              <th>STT</th>
              {/* <th>Mã khách hàng</th> */}
              <th>Tên khách hàng</th>
              <th>Số điện thoai Zalo</th>
              <th>Email</th>
              <th>Thời gian</th>
            </tr>
          </thead>
          <tbody className="table-tbody">
            {dataCost ? (
              dataCost.map((values, key) => (
                <tr key={key}>
                  <td style={{ width: "6%" }}>
                    {key + 1 + itemsPerPage * (currentPage - 1)}
                  </td>
                  {/* <td style={{ width: "17%" }}>...Loading</td> */}
                  <td style={{ width: "21%" }}>{values.name}</td>
                  <td style={{ width: "17%" }}>{values.phoneNumber}</td>
                  <td style={{ width: "22%" }}>{values.email}</td>
                  <td style={{ width: "17%" }}>
                    {formatDateH(values.createdDate)}
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
      <div className="flex-div">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Pagination
            totalPages={parseInt(totalPages)} // tổng số trang
            currentPage={currentPage} //page hiện tại
            onPageChange={onPageChange} //sự kiện khi next trang
          />
        </div>
        <div className="col-md-2 flex-div">
          <ItemsPerPageSelect
            value={itemsPerPage} //Số item trong page
            onSelect={handleItemsPerPageSelect}
          />
        </div>
      </div>
    </div>
  );
}
