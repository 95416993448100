import React from "react";
import "../../assets/css/share/Pagination.css";
import PropTypes from "prop-types";
// import _ from "lodash";

const Pagination = (props) => {
  // totalPages: tổng số trang
  // totalItems: tổng số phần tử cần phân trang
  // itemsPerPage: số lượng phần tử trong một trang
  // currentPage: trang hiện tại
  // onPageChange: hàm được gọi khi người dùng chuyển trang
  const { totalPages, currentPage, onPageChange } = props;

  // if (totalPages === 1) return null;

  // const isFirstPage = currentPage === 1; //xác định page bắt đầu
  const isLastPage = currentPage === totalPages; //pxác định page cuối cùng

  let startPage, endPage;
  if (totalPages <= 5) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage >= totalPages - 2) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  // console.log("page cuối: ", totalPages)
  // console.log("page hiện tại: ", currentPage)
  // console.log("check page cuối",isLastPage)

  return (
    <div className="margin-pagination">
      <nav>
        <ul className="pagination">
          {currentPage > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => onPageChange(currentPage - 1)}
              >
                Trang Trước
              </button>
            </li>
          )}
          {pageNumbers.map((pageNumber) => (
            <li
              className={`page-item ${
                currentPage === pageNumber ? "active" : ""
              }`}
              key={pageNumber}
            >
              <button
                className="page-link"
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          { (!isLastPage && totalPages!==0) ? (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => onPageChange(currentPage + 1)}
              >
                Trang Sau
              </button>
            </li>
          ) : (<React.Fragment/>)}
        </ul>
      </nav>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
