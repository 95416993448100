import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
//   Area,
// } from "recharts";
import "../../assets/css/share/LineChart.css";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = (props) => {
  // Dữ liệu biểu đồ
  const dataChart = props.dataLine;

  return (
    <div className="LineChart-WrapChart">
      <h2 style={{ textAlign: "center", marginTop:'20px' }}>
        Số lượng vận đơn theo ngày trong tháng {props.month} - {props.year}
      </h2>
      <div className="chart-container">
        <div className="LineChart-TitleNoteY">
          {"("}Số đơn{"*)"}
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={dataChart}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis tick={{ dx: -15 }} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="count"
              stroke="#19bc1e"
              fill="#19bc1e"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className="LineChart-TitleNoteX">{"("}Số ngày{"*)"}</div>
      </div>
    </div>
  );
};

export default Chart;
