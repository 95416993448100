import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../assets/css/page/Login.css";
import { FaEyeSlash, FaEye, FaUserAlt, FaKey } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import axios from "../config/axiosConfig";
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    // .email("Invalid email address")
    .required("Vui lòng điền tên đăng nhập"),
  password: Yup.string()
    // .min(8, "Password must be at least 8 characters")
    .required("Vui lòng điền mật khẩu"),
});

export default function Login() {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.removeItem("AuthorizationSunny");
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values) => {
    axios
      .post("sunnyAdmin/v1/auth/login", {
        username: values.email,
        password: values.password,
      })
      .then((response) => {
        localStorage.setItem("AuthorizationSunny", response.data.jwttoken);
        window.location.href = "/"
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Sai tên đăng nhập hoặc mật khẩu!",
        });
      });
  };

  return (
    <React.Fragment>
      {isDesktopOrLaptop ? (
        <div className="Login-Wrap col-md-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="login-form">
                <h1 className="login-title">Đăng nhập</h1>
                <div className="form-group login-wrap-input">
                  <div className="login-icon-input">
                    <FaUserAlt />
                  </div>
                  <Field
                    name="email"
                    className="form-control login-input login-fmU"
                  />
                </div>
                <div className="form-group login-wrap-error">
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-group login-wrap-inputP">
                  <div className="login-icon-input">
                    <FaKey />
                  </div>
                  <Field
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control login-input login-fmP"
                  />
                  <div className="login-showpass-Icon">
                    {showPassword ? (
                      <FaEyeSlash
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      />
                    ) : (
                      <FaEye
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group login-wrap-error">
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>

                <button type="submit" className="login-btn btn btn-primary">
                  Login
                </button>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="Login-Wrap col-md-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="login-form-mb">
                <h1 className="login-title-mb">Đăng nhập</h1>
                <div className="form-group login-wrap-input">
                  <div className="login-icon-input-mb">
                    <FaUserAlt />
                  </div>
                  <Field
                    name="email"
                    className="form-control login-input login-fmU-mb"
                  />
                </div>
                <div className="form-group login-wrap-error-mb">
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="login-error-mb"
                  />
                </div>

                <div className="form-group login-wrap-inputP-mb">
                  <div className="login-icon-input-mb">
                    <FaKey />
                  </div>
                  <Field
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control login-input login-fmP-mb"
                  />
                  <div className="login-showpass-Icon-mb">
                    {showPassword ? (
                      <FaEyeSlash
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      />
                    ) : (
                      <FaEye
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group login-wrap-error">
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>

                <button type="submit" className="login-btn-mb btn btn-primary">
                  Login
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </React.Fragment>
  );
}
