import React, { useState, useEffect } from "react";
import "../../assets/css/contentHome/DashBoard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from "../shared/ErrorBoundary";
import LineChart from "../shared/LineChart";
import axios, { setAuthToken } from "../../config/axiosConfig";

export default function DashBoard() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [arrayDateF, setArrayDateF] = useState();
  const [statistical, setStatistical] = useState();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  // Lấy ngày hiện tại
  const currentDate = new Date();

  // Lấy tiêng tháng, năm
  function getYear(date) {
    return date.getFullYear();
  }
  let year = getYear(selectedDate);

  function getMonth(date) {
    return date.getMonth() + 1;
  }
  let month = getMonth(selectedDate);

  // Thiết lập ngày tối đa là ngày hiện tại
  const maxDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  // format dạng yyyy-MM
  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Lấy tháng từ 0-11, nên cần +1 và thêm số 0 nếu tháng chỉ có 1 chữ số
    return `${year}-${month}`;
  }

  let monthYear = getFormattedDate(selectedDate);

  useEffect(() => {
    axios
      .post("sunnyAdmin/v1/statistics/count", {
        date: monthYear,
      })
      .then((response) => {
        // console.log(response.data);
        setStatistical(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post("sunnyAdmin/v1/statistics/waybillPerMonth", {
        month: month,
        year: year,
      })
      .then((response) => {
        setArrayDateF(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div>
      <div className="DashBoard-Filter">
        <div className="DashBoard-titleDate center-lmh">Tháng: </div>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          className="month-year-picker"
          maxDate={maxDate}
        />
        {/* <button style={{ marginLeft: "50px" }} onClick={test}>
          test
        </button> */}
      </div>

      {statistical ? (
        <div className="DashBoard-Content">
          <div className="DashBoard-WrapCard col-4 col-md-4 col-xl-4">
            <div className="DashBoard-Card color-1">
              <h2>Số bài đăng</h2>
              <div className="DashBoard-CardContent">
                {/* {statistical.postQuantity} / <strong> bài</strong> */}
                {/* {statistical.postQuantity} {"("}<strong> bài </strong>{")"} */}
                <strong>{statistical.postQuantity} bài </strong>
              </div>
            </div>
          </div>
          <div className="DashBoard-WrapCard col-4 col-md-4 col-xl-4">
            <div className="DashBoard-Card color-1">
              <h2>Đăng kí nhận báo giá</h2>
              <div className="DashBoard-CardContent">
                {/* {statistical.costQuantity} / <strong>người</strong> */}
                {/* {statistical.costQuantity} {"("}<strong> người </strong>{")"} */}
                <strong> {statistical.costQuantity} người </strong>
              </div>
            </div>
          </div>
          <div className="DashBoard-WrapCard col-4 col-md-4 col-xl-4">
            <div className="DashBoard-Card color-1">
              <h2>Tạo vận đơn</h2>
              <div className="DashBoard-CardContent">
                <strong> {statistical.waybillQuantity} đơn</strong>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="DashBoard-LineChart">
        <ErrorBoundary>
          <LineChart dataLine={arrayDateF} month={month} year={year} />
        </ErrorBoundary>
      </div>
    </div>
  );
}
