import React from "react";
import logoSunny from "../../assets/img/Logo/sunny01.png";
import "../../assets/css/share/NavBar.css";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <div className="col-md-12 NavBarWrap">
      <Link to="/dashboard">
        <img src={logoSunny} alt="logo" className="logoSunny" />
      </Link>
    </div>
  );
}
