import React, { useState, useEffect, useRef } from "react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../../assets/css/contentHome/AddContent.css";
// import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import axiosC, { setAuthToken } from "../../config/axiosConfig";
import { useParams } from "react-router-dom";

export default function EditContent() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));
  const TOKEN = localStorage.getItem("AuthorizationSunny");
  const [listSubCate, setListSubCate] = useState();
  // const [showPopover, setShowPopover] = useState(true);
  const [listCategory, setListCategory] = useState();
  const [option1Checked, setOption1Checked] = useState(false);
  const [option2Checked, setOption2Checked] = useState(false);
  const params = useParams();

  const decodeBase64 = (idEdit) => {
    const decodedString = atob(idEdit);
    return decodedString;
  };

  const handleChangeTitleVn = (event) => {
    setTitleVn(event.target.value);
  };
  const handleChangeTitleEn = (event) => {
    setTitleEn(event.target.value);
  };

  const handleChangeTitleVnMini = (event) => {
    setSubTitleVn(event.target.value);
  };

  const handleChangeTitleEnMini = (event) => {
    setSubTitleEn(event.target.value);
  };

  // --------------------------------------------------------------
  const wrapSiteBarRef = useRef(null);
  // const SCROLL_THRESHOLD = 0.1; // Ngưỡng cuộn (scroll) để ẩn Popover

  useEffect(() => {
    const handleScroll = () => {
      // const wrapSiteBarElement = wrapSiteBarRef.current;
      // if (wrapSiteBarElement) {
      //   const scrollTop = wrapSiteBarElement.scrollTop;
      //   const scrollHeight = wrapSiteBarElement.scrollHeight;
      //   const clientHeight = wrapSiteBarElement.clientHeight;
      //   const scrollPercentage = scrollTop / (scrollHeight - clientHeight);

      //   setShowPopover(scrollPercentage <= SCROLL_THRESHOLD);
      // }
    };

    const wrapSiteBarElement = wrapSiteBarRef.current;
    if (wrapSiteBarElement) {
      wrapSiteBarElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (wrapSiteBarElement) {
        wrapSiteBarElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const fileInputRef = useRef(null);

  // const fix = "http://103.77.160.122:8086/sunnyAdmin/v1/image/upload";
  const fix = `${process.env.REACT_APP_SUNNY_ADMIN_FE_URL}/sunnyAdmin/v1/image/upload`;
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${TOKEN}`,
  };

  const handleEditorReady = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return {
        upload: async () => {
          const data = new FormData();
          // console.log("first", loader.file);
          // await loader.file.then((res) => console.log("file", res));
          await loader.file.then((res) => {
            data.append("file", res);
            data.append("type", "POST_TYPE");
          });

          // console.log("data", data);

          let result = "";
          const test = async () =>
            axios
              .post(fix, data, { headers })
              .then((response) => {
                // console.log("res done");
                // const url = "http://103.77.160.122:8086";
                const url = process.env.REACT_APP_SUNNY_ADMIN_FE_URL;
                const path = response.data.response[0].path;
                result = `${url}${path}`;
                return result;
              })
              .catch((error) => {
                // Xử lý lỗi
                console.error(error);
              });
          const abc = await test();

          return {
            default: abc,
          };
        },
      };
    };
  };

  const handleEditorReady2 = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return {
        upload: async () => {
          const data = new FormData();
          // console.log("first", loader.file);
          // await loader.file.then((res) => console.log("file", res));
          await loader.file.then((res) => {
            data.append("file", res);
            data.append("type", "POST_TYPE");
          });

          // console.log("data", data)
          let result = "";
          const test = async () =>
            axios
              .post(fix, data, { headers })
              .then((response) => {
                // console.log("res done");
                // const url = "http://103.77.160.122:8086";
                const url = process.env.REACT_APP_SUNNY_ADMIN_FE_URL;
                const path = response.data.response[0].path;
                result = `${url}${path}`;
                return result;
              })
              .catch((error) => {
                // Xử lý lỗi
                console.error(error);
              });
          const abc = await test();

          return {
            default: abc,
          };
        },
      };
    };
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    axiosC
      .post("sunnyAdmin/v1/subCategory/searchByCategoryId", {
        categoryId: value,
      })
      .then((response) => {
        setListSubCate(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });

    // setShowPopover(true);
  };

  const [fileImgLoading, setFileImgLoading] = useState();
  const [nameImgThumbNail, setNameImgThumbNail] = useState();

  const setDataImageVnEn = (e) => {
    setFileImgLoading(e.target.files[0]);
    setNameImgThumbNail(e.target.files[0].name);
  };

  const handleOption2Change = (e) => {
    setOption2Checked(e.target.checked);
  };
  const handleOption1Change = (e) => {
    setOption1Checked(e.target.checked);
  };

  const [imgThumbnail, setImgThumnail] = useState("");
  const [titleVn, setTitleVn] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [contentVn, setContentVn] = useState("");
  const [contentEn, setContentEn] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subTitleVn, setSubTitleVn] = useState("");
  const [subContentVn, setSubContentVn] = useState("");
  const [subTitleEn, setSubTitleEn] = useState("");
  const [subContentEn, setSubContentEn] = useState("");

  const EditPost = () => {
    if (
      titleVn === "" ||
      titleEn === "" ||
      contentVn === "" ||
      contentEn === "" ||
      subCategoryId === "" ||
      subTitleVn === "" ||
      subContentVn === "" ||
      subTitleEn === "" ||
      subContentEn === "" ||
      typeof subCategoryId === "undefined"
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Vui lòng nhập đủ thông tin! ",
      });
    } else {
      if (fileImgLoading !== null) {
        const file = fileImgLoading;
        const formData = new FormData();
        if (file && file.type.startsWith("image")) {
          formData.append("file", file);
          formData.append("type", "POST_TYPE");
          axios
            .post(fix, formData, { headers })
            .then((response) => {
              return response.data.response[0].path;
            })
            .then((path) => {
              const bodyCreate = {
                postId: decodeBase64(params.idEdit),
                titleVn: titleVn,
                titleEn: titleEn,
                contentVn: contentVn,
                contentEn: contentEn,
                subCategoryId: subCategoryId,
                subTitleVn: subTitleVn,
                subTitleEn: subTitleEn,
                subContentVn: subContentVn,
                subContentEn: subContentEn,
                imagePresent: path,
                status: option1Checked === true ? "ACTIVE" : "INACTIVE",
                outstanding: option2Checked === true ? "ACTIVE" : "INACTIVE",
                // priority: "ACTIVE",
              };
              const bodyCreateUp = JSON.stringify(bodyCreate);
              return bodyCreateUp;
            })
            .then((bodyCreateUp) => {
              axiosC
                .post("sunnyAdmin/v1/post/update", bodyCreateUp)
                .then((response) => {
                  Swal.fire({
                    title: "Thành công!",
                    text: "Chỉnh sửa bài viết thành công",
                    icon: "success",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      axiosC
                        .post("sunnyAdmin/v1/post/search", {
                          keyword: decodeBase64(params.idEdit),
                          pageNumber: "",
                          pageSize: "",
                          status: "",
                        })
                        .then((response) => {
                          setTitleVn(response.data.response[0].titleVn);
                          setTitleEn(response.data.response[0].titleEn);
                          setContentVn(response.data.response[0].contentVn);
                          setContentEn(response.data.response[0].contentEn);
                          setSubCategoryId(
                            response.data.response[0].subCategoryId
                          );
                          setSubTitleVn(response.data.response[0].subTitleVn);
                          setSubContentVn(
                            response.data.response[0].subContentVn
                          );
                          setSubTitleEn(response.data.response[0].subTitleEn);
                          setSubContentEn(
                            response.data.response[0].subContentEn
                          );
                          setImgThumnail(
                            response.data.response[0].imagePresent
                          );
                          setOption1Checked(
                            response.data.response[0].status === "ACTIVE"
                              ? true
                              : false
                          );
                          setOption2Checked(
                            response.data.response[0].outstanding === "ACTIVE"
                              ? true
                              : false
                          );
                          setNameImgThumbNail(
                            response.data.response[0].imagePresent
                              .split("/")
                              .pop()
                          );
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
                  });
                  console.log(error);
                });
            })
            .catch((error) => {
              // Xử lý lỗi
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
              });
              console.error(error);
            });
        }
      }
      if (typeof fileImgLoading === "undefined") {
        const bodyCreate = {
          postId: decodeBase64(params.idEdit),
          titleVn: titleVn,
          titleEn: titleEn,
          contentVn: contentVn,
          contentEn: contentEn,
          subCategoryId: subCategoryId,
          subTitleVn: subTitleVn,
          subTitleEn: subTitleEn,
          subContentVn: subContentVn,
          subContentEn: subContentEn,
          imagePresent: imgThumbnail,
          status: option1Checked === true ? "ACTIVE" : "INACTIVE",
          outstanding: option2Checked === true ? "ACTIVE" : "INACTIVE",
          // priority: "ACTIVE",
        };
        const bodyCreateUp = JSON.stringify(bodyCreate);
        axiosC
          .post("sunnyAdmin/v1/post/update", bodyCreateUp)
          .then((response) => {
            Swal.fire({
              title: "Thành công!",
              text: "Chỉnh sửa bài viết thành công",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                axiosC
                  .post("sunnyAdmin/v1/post/search", {
                    keyword: decodeBase64(params.idEdit),
                    pageNumber: "",
                    pageSize: "",
                    status: "",
                  })
                  .then((response) => {
                    setTitleVn(response.data.response[0].titleVn);
                    setTitleEn(response.data.response[0].titleEn);
                    setContentVn(response.data.response[0].contentVn);
                    setContentEn(response.data.response[0].contentEn);
                    setSubCategoryId(response.data.response[0].subCategoryId);
                    setSubTitleVn(response.data.response[0].subTitleVn);
                    setSubContentVn(response.data.response[0].subContentVn);
                    setSubTitleEn(response.data.response[0].subTitleEn);
                    setSubContentEn(response.data.response[0].subContentEn);
                    setImgThumnail(response.data.response[0].imagePresent);
                    setOption1Checked(
                      response.data.response[0].status === "ACTIVE"
                        ? true
                        : false
                    );
                    setOption2Checked(
                      response.data.response[0].outstanding === "ACTIVE"
                        ? true
                        : false
                    );
                    setNameImgThumbNail(
                      response.data.response[0].imagePresent.split("/").pop()
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
            });
            console.log(error);
          });
      }
    }
  };

  const [valueCate, setValueCate] = useState();
  const [valueSubCate, setValueSubCate] = useState("");

  useEffect(() => {
    axiosC
      .post("sunnyAdmin/v1/post/search", {
        keyword: decodeBase64(params.idEdit),
        pageNumber: "",
        pageSize: "",
        status: "",
      })
      .then((response) => {
        // console.log();
        setTitleVn(response.data.response[0].titleVn);
        setTitleEn(response.data.response[0].titleEn);
        setContentVn(response.data.response[0].contentVn);
        setContentEn(response.data.response[0].contentEn);
        setSubCategoryId(response.data.response[0].subCategoryId);
        setSubTitleVn(response.data.response[0].subTitleVn);
        setSubContentVn(response.data.response[0].subContentVn);
        setSubTitleEn(response.data.response[0].subTitleEn);
        setSubContentEn(response.data.response[0].subContentEn);
        setImgThumnail(response.data.response[0].imagePresent);
        setOption1Checked(
          response.data.response[0].status === "ACTIVE" ? true : false
        );
        setOption2Checked(
          response.data.response[0].outstanding === "ACTIVE" ? true : false
        );
        setNameImgThumbNail(
          response.data.response[0].imagePresent.split("/").pop()
        );
        return response.data.response[0].subCategoryId;
      })

      .then((idSub) => {
        axiosC
          .post("sunnyAdmin/v1/subCategory/searchBySubCategoryId", {
            subCategoryId: idSub,
          })
          .then((response) => {
            setValueSubCate(response.data.subCategoryVn);
            setValueCate(response.data.categoryNameVn);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    axiosC
      .post("sunnyAdmin/v1/category/list", {})
      .then((response) => {
        setListCategory(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeclectLocation = (event) => {
    setSubCategoryId(event.target.value);
  };

  // const renderList = () => {
  //   return (
  //     <Popover id="popover-list">
  //       <Popover.Body>
  //         <Form.Group controlId="listControl">
  //           <Form.Label>Chọn chi tiết danh mục:</Form.Label>
  //           {valueSubCate ? (
  //             <Form.Control as="select" onChange={handleSeclectLocation}>
  //               <option value="">Đang chọn:{valueSubCate}</option>
  //               {listSubCate ? (
  //                 listSubCate.map((values, key) => (
  //                   <option key={values.subCategoryVn} value={values.id}>
  //                     {values.subCategoryVn}
  //                   </option>
  //                 ))
  //               ) : (
  //                 <></>
  //               )}
  //             </Form.Control>
  //           ) : (
  //             <></>
  //           )}
  //         </Form.Group>
  //       </Popover.Body>
  //       <style jsx>{`
  //         .popover {
  //           opacity: 0.4;
  //           transition: opacity 0.3s ease;
  //           background: grey;
  //         }
  //         .popover:hover {
  //           opacity: 1;
  //         }
  //         .popover-body {
  //           color: white; /* Màu chữ của Popover */
  //         }
  //       `}</style>
  //     </Popover>
  //   );
  // };

  // const abc = () => {
  //   console.log("data: ");
  // };

  return (
    <div>
      <div className="home-contentPage-title addContent-wrapDirectional flex-div">
        Quản lý nội dung /{" "}
        <div className="addContent-title">&nbsp;Chỉnh sửa</div>
        <div className="addContent-directional">
          {/* <button className="addContent-btnDirectionalUp">
            Gỡ bài
          </button> */}
          <button className="addContent-btnDirectionalSave" onClick={EditPost}>
            Lưu bài
          </button>
        </div>
      </div>

      <div className="AddContent-WrapEditor">
        <div className="col-md-9 AddContent-CkEditor">
          <div className="AddContent-WrapEditorContentVn">
            <div className="AddContent-EditorContentTitle">
              Nội dung tiếng Việt
            </div>
            <div className="AddContent-EditorContentWrapInput">
              <div className="AddContent-EditorContentTitleI">Tiêu đề:</div>
              <input
                type="text"
                value={titleVn}
                onChange={handleChangeTitleVn}
              ></input>
            </div>
            <CKEditor
              editor={ClassicEditor}
              data={contentVn}
              onReady={(editor) => {
                return handleEditorReady(editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContentVn(data);
              }}
            />
          </div>

          <div className="AddContent-WrapEditorContentEn">
            <div className="AddContent-EditorContentTitle">
              Nội dung tiếng Anh
            </div>
            <div className="AddContent-EditorContentWrapInput">
              <div className="AddContent-EditorContentTitleI">Tiêu đề:</div>
              <input
                type="text"
                value={titleEn}
                onChange={handleChangeTitleEn}
              ></input>
            </div>
            <CKEditor
              editor={ClassicEditor}
              data={contentEn}
              onReady={(editor) => {
                return handleEditorReady2(editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log("data onchange", data);
                setContentEn(data);
              }}
            />
          </div>
        </div>

        <div className="col-md-3 AddContent-WrapSiteBar" ref={wrapSiteBarRef}>
          <div className="card AddContent-SiteBar">
            <div className="AddContent-SiteBarTitle">Thiết lập bài đăng</div>
            <div className="AddContent-WrapMiniContent">
              <div>
                {valueCate ? (
                  <Form>
                    <Form.Group controlId="selectControl">
                      <Form.Label>Vị trí:</Form.Label>
                      <Form.Control as="select" onChange={handleSelectChange}>
                        <option value="">
                          Vị trí hiện tại: {valueCate.toUpperCase()}
                        </option>
                        {listCategory ? (
                          listCategory.map((values, key) => (
                            <option key={key} value={values.id}>
                              {values.categoryVn.toUpperCase()}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                ) : (
                  <></>
                )}
                <div style={{marginTop:'20px'}}>
                  {valueSubCate ? (
                    <Form.Control as="select" onChange={handleSeclectLocation}>
                      <option value="">Đang chọn: {valueSubCate}</option>
                      {listSubCate ? (
                        listSubCate.map((values, key) => (
                          <option key={values.subCategoryVn} value={values.id}>
                            {values.subCategoryVn}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Control>
                  ) : (
                    <></>
                  )}
                </div>

                {/* <OverlayTrigger
                  show={showPopover}
                  placement="top"
                  overlay={renderList()}
                  target="selectControl"
                  transition={false}
                  popperConfig={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [60, 60],
                        },
                      },
                    ],
                  }}
                >
                  <div />
                </OverlayTrigger> */}
              </div>

              <div className="AddContent-wrapOption">
                <Form.Group>
                  <Form.Label style={{ fontWeight: "19px" }}>
                    Tuỳ chọn:
                  </Form.Label>
                  {/* <Form.Check
                    type="checkbox"
                    label="Bài viết chính"
                    checked={option1Checked}
                    onChange={handleOption1Change}
                  /> */}
                  <Form.Check
                    type="checkbox"
                    label="Bài viết nổi bật"
                    checked={option2Checked}
                    onChange={handleOption2Change}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Bài viết chính"
                    checked={option1Checked}
                    onChange={handleOption1Change}
                  />
                </Form.Group>
              </div>

              <div className="AddContent-wrapSummary">
                <div className="AddContent-SummaryTitle">Tóm tắt</div>

                {/* <div className="file-input">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={setDataImageVnEn}
                  ></input>
                </div> */}
                <div>
                  <label htmlFor="file-input" className="custom-file-upload">
                    Chọn file
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    id="file-input"
                    onChange={setDataImageVnEn}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  {/* {imgThumbnail ? (
                    <img
                      className="AddContent-IconCheckImgSub"
                      alt="img"
                      src={ok}
                    />
                  ) : (
                    <></>
                  )} */}
                  <div>
                    Đã chọn: <strong>{nameImgThumbNail}</strong>{" "}
                  </div>
                </div>

                <div className="AddContent-EditorContentWrapInputEnVn">
                  <div className="AddContent-EditorContentInputEnVn">
                    {"(*)"} Nội dung tiếng Việt
                  </div>
                  <div className="AddContent-EditorContentWrapInput">
                    <div className="AddContent-EditorContentTitleI">
                      Tiêu đề:
                    </div>
                    <input
                      type="text"
                      value={subTitleVn}
                      onChange={handleChangeTitleVnMini}
                    ></input>
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={subContentVn}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setSubContentVn(data);
                    }}
                  />
                </div>

                <div className="AddContent-EditorContentWrapInputEnVn">
                  <div className="AddContent-EditorContentInputEnVn">
                    {"(*)"} Nội dung tiếng Anh
                  </div>
                  <div className="AddContent-EditorContentWrapInput">
                    <div className="AddContent-EditorContentTitleI">
                      Tiêu đề:
                    </div>
                    <input
                      type="text"
                      value={subTitleEn}
                      onChange={handleChangeTitleEnMini}
                    ></input>
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={subContentEn}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setSubContentEn(data);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
