import React, { useState, useEffect, useRef } from "react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../../assets/css/contentHome/AddContent.css";
// import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Form} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import axiosC, { setAuthToken } from "../../config/axiosConfig";

export default function AddContent() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));
  const TOKEN = localStorage.getItem("AuthorizationSunny");
  const [listCategory, setListCategory] = useState();
  const [listSubCate, setListSubCate] = useState();
  const [idLocationPost, setIdLocationPost] = useState();

  const [option1Checked, setOption1Checked] = useState(false); // pick bài viết
  const [option2Checked, setOption2Checked] = useState(false);

  const handleOption1Change = (e) => {
    setOption1Checked(e.target.checked);
  };

  const handleOption2Change = (e) => {
    setOption2Checked(e.target.checked);
  };

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${TOKEN}`,
  };
  // const fix = "http://103.77.160.122:8086/sunnyAdmin/v1/image/upload";
  const fix = `${process.env.REACT_APP_SUNNY_ADMIN_FE_URL}/sunnyAdmin/v1/image/upload`;

  const [editorData, setEditorData] = useState("");

  const [editorData2, setEditorData2] = useState("");

  const [editorDataSubContentVn, setEditorDataSubContentVn] = useState("");

  const [editorDataSubContentEn, setEditorDataSubContentEn] = useState("");

  // --------------------------------------------------------------------------------------------
  const handleEditorReady = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return {
        upload: async () => {
          const data = new FormData();
          // console.log("first", loader.file);
          // await loader.file.then((res) => console.log("file", res));
          await loader.file.then((res) => {
            data.append("file", res);
            data.append("type", "BLOG");
          });

          // console.log("data", data);

          let result = "";
          const test = async () =>
            axios
              .post(fix, data, { headers })
              .then((response) => {
                // console.log("res done");
                // const url = "http://192.168.110.145:8086";
                const url = process.env.REACT_APP_SUNNY_ADMIN_FE_URL;
                const path = response.data.response[0].path;
                result = `${url}${path}`;
                return result;
              })
              .catch((error) => {
                // Xử lý lỗi
                console.error(error);
              });
          const abc = await test();

          return {
            default: abc,
          };
        },
      };
    };
  };

  const handleEditorReady2 = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return {
        upload: async () => {
          const data = new FormData();
          // console.log("first", loader.file);
          // await loader.file.then((res) => console.log("file", res));
          await loader.file.then((res) => {
            data.append("file", res);
            data.append("type", "BLOG");
          });

          // console.log("data", data)
          let result = "";
          const test = async () =>
            axios
              .post(fix, data, { headers })
              .then((response) => {
                // console.log("res done");
                // const url = "http://103.77.160.122:8086";
                const url = process.env.REACT_APP_SUNNY_ADMIN_FE_URL;
                const path = response.data.response[0].path;
                result = `${url}${path}`;
                return result;
              })
              .catch((error) => {
                // Xử lý lỗi
                console.error(error);
              });
          const abc = await test();

          return {
            default: abc,
          };
        },
      };
    };
  };

  const test = () => {
    // console.log(idLocationPost);
    // console.log("bodyCreate: ", bodyCreate);
  };

  // chọn vị trí bài viết
  // const [showPopover, setShowPopover] = useState(false);
  const handleSelectChange = (event) => {
    const value = event.target.value;
    axiosC
      .post("sunnyAdmin/v1/subCategory/searchByCategoryId", {
        categoryId: value,
      })
      .then((response) => {
        setListSubCate(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });

    // setShowPopover(true);
  };

  const handleSeclectLocation = (event) => {
    setIdLocationPost(event.target.value);
    // setNameSubCate(event.target.key);
    // setShowPopover(false);
  };

  // const renderList = () => {
  //   return (
  //     <Popover id="popover-list">
  //       <Popover.Body>
  //         <Form.Group controlId="listControl">
  //           <Form.Label>Chọn chi tiết danh mục:</Form.Label>
  //           <Form.Control as="select" onChange={handleSeclectLocation}>
  //             <option value="">--Vui lòng chọn--</option>
  //             {listSubCate ? (
  //               listSubCate.map((values, key) => (
  //                 <option key={values.subCategoryVn} value={values.id}>
  //                   {values.subCategoryVn}
  //                 </option>
  //               ))
  //             ) : (
  //               <></>
  //             )}
  //           </Form.Control>
  //         </Form.Group>
  //       </Popover.Body>
  //     </Popover>
  //   );
  // };

  const [inputTitleVn, setInputTitleVn] = useState("");
  const handleChangeTitleVn = (event) => {
    setInputTitleVn(event.target.value);
  };

  const [inputTitleEn, setInputTitleEn] = useState("");
  const handleChangeTitleEn = (event) => {
    setInputTitleEn(event.target.value);
  };

  const [inputTitleVnMini, setInputTitleVnMini] = useState("");
  const handleChangeTitleVnMini = (event) => {
    setInputTitleVnMini(event.target.value);
  };

  const [inputTitleEnMini, setInputTitleEnMini] = useState("");
  const handleChangeTitleEnMini = (event) => {
    setInputTitleEnMini(event.target.value);
  };

  const fileInputRef = useRef(null);

  const [fileImgLoading, setFileImgLoading] = useState();
  const [nameImgThumbNail, setNameImgThumbNail] = useState("Vui lòng chọn ảnh");

  const setDataImageVnEn = (e) => {
    setFileImgLoading(e.target.files[0]);
    setNameImgThumbNail(e.target.files[0].name);
  };

  const UpPost = () => {
    const file = fileImgLoading;
    if (
      inputTitleVn === "" ||
      inputTitleEn === "" ||
      editorData === "" ||
      editorData2 === "" ||
      idLocationPost === "" ||
      inputTitleVnMini === "" ||
      inputTitleEnMini === "" ||
      editorDataSubContentVn === "" ||
      editorDataSubContentEn === "" ||
      typeof file === "undefined" ||
      typeof idLocationPost === "undefined"
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Vui lòng nhập đủ thông tin! ",
      });
    } else {
      const formData = new FormData();
      if (file && file.type.startsWith("image")) {
        formData.append("file", file);
        formData.append("type", "IMAGE_PRESENT");
        axios
          .post(fix, formData, { headers })
          .then((response) => {
            return response.data.response[0].path;
          })
          .then((path) => {
            const bodyCreate = {
              titleVn: inputTitleVn,
              titleEn: inputTitleEn,
              contentVn: editorData,
              contentEn: editorData2,
              subCategoryId: idLocationPost,
              subTitleVn: inputTitleVnMini,
              subTitleEn: inputTitleEnMini,
              subContentVn: editorDataSubContentVn,
              subContentEn: editorDataSubContentEn,
              imagePresent: path,
              priority: "ACTIVE",
              status: option1Checked === true ? "ACTIVE" : "INACTIVE",
              outstanding: option2Checked === true ? "ACTIVE" : "INACTIVE",
            };
            const bodyCreateUp = JSON.stringify(bodyCreate);
            return bodyCreateUp;
          })
          .then((bodyCreateUp) => {
            console.log("body: ", bodyCreateUp);
            axiosC
              .post("sunnyAdmin/v1/post/create", bodyCreateUp)
              .then((response) => {
                Swal.fire({
                  title: "Thành công!",
                  text: "Thêm bài viết mới thành công",
                  icon: "success",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Đã có lỗi up ảnh, vui lòng thử lại! ",
            });
            console.error(error);
          });
      }
    }
  };

  useEffect(() => {
    axiosC
      .post("sunnyAdmin/v1/category/list", {})
      .then((response) => {
        setListCategory(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListSubCate()
  },[listCategory])

  return (
    <div>
      <div className="home-contentPage-title addContent-wrapDirectional flex-div">
        Quản lý nội dung /{" "}
        <div className="addContent-title" onClick={test}>
          &nbsp;Thêm mới
        </div>
        <div className="addContent-directional">
          {/* <button className="addContent-btnDirectionalUp">Đăng bài</button> */}
          <button className="addContent-btnDirectionalSave" onClick={UpPost}>
            Lưu bài
          </button>
        </div>
      </div>
      <div className="AddContent-WrapEditor">
        <div className="col-md-9 AddContent-CkEditor">
          <div className="AddContent-WrapEditorContentVn">
            <div className="AddContent-EditorContentTitle">
              Nội dung tiếng Việt
            </div>
            <div className="AddContent-EditorContentWrapInput">
              <div className="AddContent-EditorContentTitleI">Tiêu đề:</div>
              <input
                type="text"
                value={inputTitleVn}
                onChange={handleChangeTitleVn}
              ></input>
            </div>
            <CKEditor
              editor={ClassicEditor}
              data={editorData}
              onReady={(editor) => {
                return handleEditorReady(editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log("data onchange 222", data);
                setEditorData(data);
              }}
            />
          </div>

          <div className="AddContent-WrapEditorContentEn">
            <div className="AddContent-EditorContentTitle">
              Nội dung tiếng Anh
            </div>
            <div className="AddContent-EditorContentWrapInput">
              <div className="AddContent-EditorContentTitleI">Tiêu đề:</div>
              <input
                type="text"
                value={inputTitleEn}
                onChange={handleChangeTitleEn}
              ></input>
            </div>
            <CKEditor
              editor={ClassicEditor}
              data={editorData2}
              onReady={(editor) => {
                return handleEditorReady2(editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log("data onchange", data);
                setEditorData2(data);
              }}
            />
          </div>
        </div>
        <div className="col-md-3 AddContent-WrapSiteBar">
          <div className="card AddContent-SiteBar">
            <div className="AddContent-SiteBarTitle">Thiết lập bài đăng</div>
            <div className="AddContent-WrapMiniContent">
              <div>
                <Form>
                  <Form.Group controlId="selectControl">
                    <Form.Label>Vị trí:</Form.Label>
                    <Form.Control as="select" onChange={handleSelectChange}>
                      <option value="">--Vui lòng chọn vị trí--</option>
                      {listCategory ? (
                        listCategory.map((values, key) => (
                          <option key={key} value={values.id}>
                            {values.categoryVn.toUpperCase()}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Form>

                <div style={{marginTop:'20px'}}>
                  <Form.Group controlId="listControl">
                    <Form.Label>Chọn chi tiết danh mục:</Form.Label>
                    <Form.Control as="select" onChange={handleSeclectLocation}>
                      <option value="">--Vui lòng chọn--</option>
                      {listSubCate ? (
                        listSubCate.map((values, key) => (
                          <option key={values.subCategoryVn} value={values.id}>
                            {values.subCategoryVn}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Control>
                  </Form.Group>
                </div>
                {/* <OverlayTrigger
                  show={showPopover}
                  placement="bottom"
                  overlay={renderList()}
                  target="selectControl"
                  transition={false}
                  popperConfig={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [80, 12],
                        },
                      },
                    ],
                  }}
                >
                  <div />
                </OverlayTrigger> */}
                {/* <div>Đã chọn: {}</div> */}
              </div>

              <div className="AddContent-wrapOption">
                <Form.Group>
                  <Form.Label style={{ fontWeight: "19px" }}>
                    Tuỳ chọn:
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Bài viết chính"
                    checked={option1Checked}
                    onChange={handleOption1Change}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Bài viết nổi bật"
                    checked={option2Checked}
                    onChange={handleOption2Change}
                  />
                </Form.Group>
              </div>

              <div className="AddContent-wrapSummary">
                <div className="AddContent-SummaryTitle">Tóm tắt</div>

                {/* <div className="file-input">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={setDataImageVnEn}
                  ></input>
                </div> */}
                <div>
                  <label htmlFor="file-input" className="custom-file-upload">
                    Chọn file
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    id="file-input"
                    onChange={setDataImageVnEn}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <div>
                    Đã chọn: <strong>{nameImgThumbNail}</strong>{" "}
                  </div>
                  {/* {checkImgSub ? (
                    <img
                      className="AddContent-IconCheckImgSub"
                      alt="icon-check"
                      src={ok}
                    />
                  ) : (
                    <></>
                  )} */}
                </div>

                <div className="AddContent-EditorContentWrapInputEnVn">
                  <div className="AddContent-EditorContentInputEnVn">
                    {"(*)"} Nội dung tiếng Việt
                  </div>
                  <div className="AddContent-EditorContentWrapInput">
                    <div className="AddContent-EditorContentTitleI">
                      Tiêu đề:
                    </div>
                    <input
                      type="text"
                      value={inputTitleVnMini}
                      onChange={handleChangeTitleVnMini}
                    ></input>
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorDataSubContentVn}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorDataSubContentVn(data);
                    }}
                  />
                </div>

                <div className="AddContent-EditorContentWrapInputEnVn">
                  <div className="AddContent-EditorContentInputEnVn">
                    {"(*)"} Nội dung tiếng Anh
                  </div>
                  <div className="AddContent-EditorContentWrapInput">
                    <div className="AddContent-EditorContentTitleI">
                      Tiêu đề:
                    </div>
                    <input
                      type="text"
                      value={inputTitleEnMini}
                      onChange={handleChangeTitleEnMini}
                    ></input>
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorDataSubContentEn}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorDataSubContentEn(data);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
