import React from "react";
import * as XLSX from "xlsx";
import "../../assets/css/page/Home.css";

function ExportExcel({ title, headers, data }) {
  const handleExport = () => {
    // const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  return (
    <button className="clw btn ExportExcelBtn" onClick={handleExport}>
      Xuất file Excel
    </button>
  );
}

export default ExportExcel;
