import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Cập nhật state để hiển thị fallback UI thay vì lỗi thực tế.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Bạn có thể gửi thông tin lỗi đến server ở đây.
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI nếu xảy ra lỗi.
      return <h1 style={{padding:'40px'}}>Something went wrong.</h1>;
    }

    // Nếu không có lỗi, render component con bên trong.
    return this.props.children;
  }
}

export default ErrorBoundary;
