import React, { useState, useEffect } from "react";
import "../../assets/css/contentHome/ContentManagement.css";
import { FaSearch, FaPencilAlt } from "react-icons/fa";
import IconFilter from "../../assets/img/Icon/filter.png";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import Pagination from "../shared/Pagination";
import ItemsPerPageSelect from "../shared/ItemsPerPageSelect";
// import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axios, { setAuthToken } from "../../config/axiosConfig";
import { Form as FormSelect } from "react-bootstrap";
// import Pin from "../../assets/img/Icon/pin.png";
// import UnPin from "../../assets/img/Icon/unPin.png";

export default function ContentManagement() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));
  const [dataContent, setDataContent] = useState();
  const [inputValue, setInputValue] = useState("");
  const [dataStatus, setDataStatus] = useState(""); //trang thai status

  const [listCategory, setListCategory] = useState();
  //phân trang==============================================================
  const [currentPage, setCurrentPage] = useState(1); //page hiện tại
  const [itemsPerPage, setItemsPerPage] = useState(10); //số lượng phần tử trong một trang
  const [totalPages, setTotalPages] = useState(1); // tổng số page

  const [dataFilterNow, setDataFilterNow] = useState();
  const encodeBase64 = (str) => {
    const encodedString = btoa(str);
    return encodedString;
  };

  // const [pinP, setPinP] = useState(true);

  //format về ngày tháng năm giờ phút----------------------------------------------------
  const formatDateH = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`; //padStart() để thêm số 0 vào trước giá trị của các thành phần ngày giờ
    return formattedDate;
  };

  // thực hiện ấn vào nút search---------------------------------------
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchData(event.target.value);
    }
  };
  const searchData = (inputValue) => {
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/post/search", {
        keyword: inputValue,
        status: dataStatus,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setTotalPages(response.data.totalPages);
        setDataContent(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const bodySearch = {
      keyword: inputValue,
      status: dataStatus,
      pageNumber: pageNumber,
      pageSize: itemsPerPage,
    };
    const bodySearchFinal = JSON.stringify(bodySearch);
    axios
      .post("sunnyAdmin/v1/post/search", bodySearchFinal)
      .then((response) => {
        setTotalPages(response.data.totalPages);
        setDataContent(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleItemsPerPageSelect = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/post/search", {
        keyword: inputValue,
        status: dataStatus,
        pageNumber: 1,
        pageSize: value,
      })
      .then((response) => {
        setTotalPages(response.data.totalPages);
        setDataContent(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fill lại bảng giá trị khi chọn trạng thái tài khoản--------------------------------------------------
  const handleSelectStatus = (event) => {
    setDataStatus(event.target.value);
    axios
      .post("sunnyAdmin/v1/post/search", {
        keyword: inputValue,
        status: event.target.value,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        console.log(response.data);
        setTotalPages(response.data.totalPages);
        setDataContent(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // modal filter
  const [valueFilter, setValueFilter] = useState({
    creator: "",
    updater: "",
    category: "",
    status: "",
    priority: false,
    timeStart: "",
    timeEnd: "",
  });

  const validationSchema = Yup.object().shape({
    creator: Yup.string(),
    updater: Yup.string(),
    category: Yup.string(),
    timeStart: Yup.date(),
    status: Yup.string(),
    priority: Yup.string(),
    timeEnd: Yup.date().test(
      "timeEnd",
      "Thời kết thúc không được nhỏ hơn thời gian bắt đầu",
      function (value) {
        const { timeStart } = this.parent;
        if (!timeStart || !value) return true; // Nếu bị null hoặc undefined thì skip validate
        return new Date(value) >= new Date(timeStart);
      }
    ),
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const handleCloseFilter = () => setShowModalFilter(false);
  const handleShowFilter = () => {
    setValueFilter({
      creator: dataFilterNow ? dataFilterNow.createdBy : "",
      updater: dataFilterNow ? dataFilterNow.updatedBy : "",
      category: dataFilterNow ? dataFilterNow.categoryId : "",
      status: dataFilterNow ? dataFilterNow.status : "",
      priority: dataFilterNow ? dataFilterNow.priority : "",
      timeStart: dataFilterNow ? dataFilterNow.startDate : "",
      timeEnd: dataFilterNow ? dataFilterNow.endDate : "",
    });
    setShowModalFilter(true);
  };

  // const setStatusToPin = () => {
  //   setPinP(true);
  // };

  // const setStatusToUnPin = () => {
  //   setPinP(false);
  // };

  const test = () => {
    console.log(dataFilterNow);
  };

  useEffect(() => {
    axios
      .post("sunnyAdmin/v1/post/list", {
        status: dataStatus,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setTotalPages(response.data.totalPage);
        setDataContent(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post("sunnyAdmin/v1/category/list", {})
      .then((response) => {
        setListCategory(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="home-contentPage-title" onClick={test}>
        Quản lý nội dung
      </div>
      <div className="contentM-option flex-div col-md-12">
        <div className="flex-div">
          <div className="contentM-wrapBtnAdd">
            <Link to="/content-management/add-content">
              <button className="contentM-btnAdd">Thêm mới</button>
            </Link>
          </div>

          <div className="contentM-wrapBtnAdd">
            <FormSelect.Select
              onChange={handleSelectStatus}
              className="account-wrapSelectStatus font18"
            >
              <option value="">Tất cả trạng thái</option>
              <option value="ACTIVE">Đăng</option>
              <option value="INACTIVE">Không đăng</option>
            </FormSelect.Select>
          </div>
        </div>
        <div className="contentM-navbarRight">
          <div className="contentM-searchAddContainer">
            <input
              type="text"
              placeholder="Tìm kiếm"
              name="search"
              className="contentM-inputSearch"
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyPress}
            />
            <button
              className="contentM-btnSearch"
              onClick={() => searchData(inputValue)}
            >
              <FaSearch />
            </button>
          </div>
          <div className="contentM-btnFilter" style={{ display: "none" }}>
            <img alt="" src={IconFilter} onClick={handleShowFilter} />
          </div>
        </div>
      </div>
      <div className="contentM-WrapTable table-responsive">
        <Table hover className=" contentM-Table">
          <thead className="table-thead">
            <tr>
              <th style={{textAlign:'center'}}>STT</th>
              <th>Bài viết</th>
              <th>Trạng thái</th>
              <th>Người tạo</th>
              <th>Lịch sử tạo</th>
              <th>Người đăng</th>
              <th>Lịch sử đăng</th>
              <th>Danh mục</th>
              <th style={{ textAlign: "center" }}>Tác vụ</th>
            </tr>
          </thead>
          <tbody className="table-tbody">
            {dataContent ? (
              dataContent.map((value, key) => (
                <tr key={key}>
                  <td style={{ width: "1%", textAlign:'center' }}>
                    {key + 1 + itemsPerPage * (currentPage - 1)}
                  </td>
                  <td style={{ width: "21%" }}>{value.titleVn}</td>
                  <td style={{ width: "10%" }}>{value.status === "ACTIVE" ? "Đăng" : "Không đăng"}</td>
                  <td style={{ width: "12%" }}>{value.createdBy}</td>
                  <td style={{ width: "12%" }}>
                    {value.createdDate === null
                      ? "---"
                      : formatDateH(value.createdDate)}
                  </td>
                  <td style={{ width: "12%" }}>{value.updatedBy}</td>
                  <td style={{ width: "12%" }}>
                    {value.updatedDate !== null
                      ? formatDateH(value.updatedDate)
                      : ""}
                  </td>
                  <td style={{ width: "10%" }}>{value.categoryVn}</td>
                  <td style={{ width: "10%", textAlign: "center" }}>
                    {/* {pinP ? (
                      <img
                        onClick={setStatusToUnPin}
                        alt="ghim"
                        src={Pin}
                        className="contentM-iconPin col-md-4"
                      />
                    ) : (
                      <img
                        onClick={setStatusToPin}
                        alt="khong ghim"
                        src={UnPin}
                        className="contentM-iconPin col-md-4"
                      />
                    )} */}
                    {/* <FaTrashAlt className="contentM-iconAction col-md-4" /> */}
                    <Link to={`edit-content/${encodeBase64(value.id)}`}>
                      <FaPencilAlt className="contentM-iconAction col-md-4" />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>

      <div className="flex-div">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Pagination
            totalPages={parseInt(totalPages)} // tổng số trang
            currentPage={currentPage} //page hiện tại
            onPageChange={onPageChange} //sự kiện khi next trang
          />
        </div>
        <div className="col-md-2 flex-div">
          <ItemsPerPageSelect
            value={itemsPerPage} //Số item trong page
            onSelect={handleItemsPerPageSelect}
          />
        </div>
      </div>

      {/* ================================== Modal filter================================== */}
      <Modal show={showModalFilter} onHide={handleCloseFilter} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Lọc nội dung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              creator: valueFilter.creator,
              updater: valueFilter.updater,
              category: valueFilter.category,
              priority: valueFilter.priority,
              status: valueFilter.status,
              timeStart: valueFilter.timeStart,
              timeEnd: valueFilter.timeEnd,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const bodyFilter = {
                createdBy: values.creator,
                updatedBy: values.updater,
                categoryId: values.category,
                priority: values.category,
                startDate: values.timeStart,
                endDate: values.timeEnd,
                status: values.status,
              };
              setDataFilterNow(bodyFilter);
              const bodyUpdate = JSON.stringify(bodyFilter);
              console.log(bodyUpdate);
              handleCloseFilter();
              axios
                .post("sunnyAdmin/v1/post/filter", bodyUpdate)
                .then((response) => {
                  console.log(response.data);
                  setTotalPages(response.data.totalPage);
                  setDataContent(response.data.response);
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div>
                  <div className="flex-div contentM-filterItem">
                    <div className="col-md-6 center-lmh">Người tạo</div>
                    <div className="col-md-6">
                      <Field
                        type="text"
                        name="creator"
                        className="col-md-12 contentM-filterItemInput"
                        id="creator"
                        value={values.creator}
                      />
                      <ErrorMessage
                        name="creator"
                        className="col-md-12 contentM-filterItemError"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="flex-div contentM-filterItem">
                    <div className="col-md-6 center-lmh">Người cập nhật</div>
                    <div className="col-md-6">
                      <Field
                        type="text"
                        name="updater"
                        className="col-md-12 contentM-filterItemInput"
                        id="updater"
                        value={values.updater}
                      />
                      <ErrorMessage
                        name="updater"
                        className="col-md-12 contentM-filterItemError"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="flex-div contentM-filterItem">
                    <div className="col-md-6 center-lmh">Danh mục</div>
                    <div className="col-md-6">
                      <Field
                        as="select"
                        name="category"
                        className="col-md-12 contentM-filterItemSelect"
                        id="category"
                        value={values.category}
                      >
                        <option value="">Danh mục</option>
                        {listCategory ? (
                          listCategory.map((values, key) => (
                            <option key={key} value={values.id}>
                              {values.categoryVn}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Field>
                      <ErrorMessage
                        name="category"
                        className="col-md-12 contentM-filterItemError"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="flex-div contentM-filterItem">
                    <div className="col-md-6 center-lmh">Trạng thái</div>
                    <div className="col-md-6">
                      <Field
                        as="select"
                        name="status"
                        className="col-md-12 contentM-filterItemSelect"
                        id="status"
                        value={values.status}
                      >
                        <option value="">Tất cả</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        className="col-md-12 contentM-filterItemError"
                        component="div"
                      />
                    </div>
                  </div>
                  {/* <div className="flex-div contentM-filterItem">
                    <div className="col-md-6 center-lmh">Bài viết đã ghim</div>
                    <div className="col-md-6">
                      <Field
                        as="select"
                        name="priority"
                        className="col-md-12 contentM-filterItemSelect"
                        id="priority"
                        value={values.priority}
                      >
                        <option value="">Tất cả</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </Field>
                      <ErrorMessage
                        name="priority"
                        className="col-md-12 contentM-filterItemError"
                        component="div"
                      />
                    </div>
                  </div> */}
                  <div className="">
                    <div className="contentM-filterItem-titleTime">
                      Thời gian tạo:
                    </div>
                    <div className="contentM-filterItemTime">
                      <div className="col-md-12 flex-div contentM-filterItem">
                        <div className="col-md-2 center-lmh">Từ: </div>
                        <div className="col-md-10">
                          <Field
                            type="date"
                            name="timeStart"
                            className="col-md-12 contentM-filterItemInput"
                            value={values.timeStart}
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        name="timeStart"
                        component="div"
                        className="col-md-12 contentM-filterItemError"
                      />
                      <div className="col-md-12 flex-div contentM-filterItem">
                        <div className="col-md-2 center-lmh">Đến: </div>
                        <div className="col-md-10">
                          <Field
                            type="date"
                            name="timeEnd"
                            className="col-md-12 contentM-filterItemInput"
                            value={values.timeEnd}
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        name="timeEnd"
                        component="div"
                        className="col-md-12 contentM-filterItemError"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-div">
                  <div className="contentM-filter-Wrapbtn">
                    <button
                      type="submit"
                      className="contentM-btnAccept btn btn-success"
                    >
                      Lọc
                    </button>
                    <button
                      onClick={handleCloseFilter}
                      className="contentM-btnCancel btn"
                    >
                      Huỷ
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
