import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "./page/Home";
import Login from "./page/Login";
import ContentManagement from "./component/contentHome/ContentManagement";
import Account from "./component/contentHome/Account";
import ServiceQuote from "./component/contentHome/ServiceQuote";
import Category from "./component/contentHome/Category";
import SubCategory from "./component/contentHome/SubCategory";
import AddContent from "./component/contentHome/AddContent";
import EditContent from "./component/contentHome/EditContent";
import DashBoard from "./component/contentHome/DashBoard";

export default function App() {
  let routes = [
    {
      path: "/",
      element: <Home />,
      children: [
        {
          index: true,
          element: <DashBoard />,
        },
        {
          path: "content-management",
          element: <ContentManagement />,
        },
        {
          path: "content-management/add-content",
          element: <AddContent />,
        },
        {
          path: "content-management/edit-content/:idEdit",
          element: <EditContent />,
        },
        {
          path: "account",
          element: <Account />,
        },
        {
          path: "service-quote",
          element: <ServiceQuote />,
        },
        {
          path: "category",
          element: <Category />,
        },
        {
          path: "sub-category",
          element: <SubCategory />,
        },
        {
          path: "dashboard",
          element: <DashBoard />,
        },
      ],
    },
    { path: "/login", element: <Login /> },
  ];
  let element = useRoutes(routes);
  return <div className="home">{element}</div>;
}
