import React, { useState, useEffect } from "react";
import { FaSearch, FaPencilAlt } from "react-icons/fa";
import "../../assets/css/contentHome/Account.css";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import Pagination from "../shared/Pagination";
import ItemsPerPageSelect from "../shared/ItemsPerPageSelect";
import axios, { setAuthToken } from "../../config/axiosConfig";
import { Form as FormSelect } from "react-bootstrap";
import Swal from "sweetalert2";

export default function Account() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));
  const [inputValue, setInputValue] = useState("");
  //phân trang==============================================================
  const [currentPage, setCurrentPage] = useState(1); //page hiện tại
  const [itemsPerPage, setItemsPerPage] = useState(10); //số lượng phần tử trong một trang
  const [totalPages, setTotalPages] = useState(1); // tổng số page
  const [dataStatus, setDataStatus] = useState(""); //trang thai status
  const [dataAccount, setDataAcount] = useState();

  // thực hiện ấn vào nút search---------------------------------------
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchData(event.target.value);
    }
  };
  const searchData = (inputValue) => {
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/account/search", {
        status: dataStatus,
        keyword: inputValue,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setDataAcount(response.data.response);
        setTotalPages(response.data.totalPage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // sự kiện chuyển trang
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    axios
      .post("sunnyAdmin/v1/account/search", {
        status: dataStatus,
        keyword: inputValue,
        pageNumber: pageNumber,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setDataAcount(response.data.response);
        setTotalPages(response.data.totalPage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle thay đổi số phần tử trang
  const handleItemsPerPageSelect = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/account/search", {
        status: dataStatus,
        keyword: inputValue,
        pageNumber: 1,
        pageSize: value,
      })
      .then((response) => {
        // console.log(response.data)
        setDataAcount(response.data.response);
        setTotalPages(response.data.totalPage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showModalAdd, setShowModalAdd] = useState(false);
  const handleCloseAdd = () => setShowModalAdd(false);
  const handleShowAdd = () => {
    setShowModalAdd(true);
  };

  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseEdit = () => setShowModalEdit(false);
  const handleShowEdit = async (idUser) => {
    const dataU = await axios
      .post("sunnyAdmin/v1/account/search", {
        status: dataStatus,
        keyword: idUser,
        pageNumber: "1",
        pageSize: "",
      })
      .then((response) => {
        console.log("aaaaaa", response.data);
        setInfoEditAccount({
          password: "",
          id: response.data.response[0].userId,
          name: response.data.response[0].fullName,
          gender: response.data.response[0].gender,
          birthday: response.data.response[0].birthDay,
          phoneNumber: response.data.response[0].phoneNumber,
          address: response.data.response[0].address,
          status: response.data.response[0].status === "ACTIVE" ? true : false,
        });
      })
      .then(() => {
        setShowModalEdit(true);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Đã có lỗi, vui lòng thử lại!",
        });
      });
    console.log(dataU);
    // console.log(idUser);
    // setShowModalEdit(true);
  };

  //fill lại bảng giá trị khi chọn trạng thái tài khoản--------------------------------------------------
  const handleSelectStatus = (event) => {
    setDataStatus(event.target.value);
    axios
      .post("sunnyAdmin/v1/account/search", {
        status: event.target.value,
        keyword: inputValue,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setDataAcount(response.data.response);
        setTotalPages(response.data.totalPage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //--------------- modal add account------------------------------------
  const [infoAddAccount] = useState({
    name: "",
    birthday: "",
    phoneNumber: "",
    gender: "",
    status: true,
    password: "",
    userName: "",
    email: "",
    // role: "",
    address: "",
  });
  const initialValues = {
    name: infoAddAccount.name,
    birthday: infoAddAccount.birthday,
    phoneNumber: infoAddAccount.phoneNumber,
    gender: infoAddAccount.gender,
    status: infoAddAccount.status,
    password: infoAddAccount.password,
    userName: infoAddAccount.userName,
    email: infoAddAccount.email,
    // role: infoAddAccount.role,
    address: infoAddAccount.address,
  };
  const validationSchemaAdd = Yup.object().shape({
    name: Yup.string().required("Vui lòng nhập họ tên"),
    birthday: Yup.date()
      .max(new Date(), "Ngày sinh không được lớn hơn ngày hiện tại")
      .required("Vui lòng chọn ngày sinh"),
    phoneNumber: Yup.string()
      .matches(/(03|05|07|08|09)+([0-9]{8})\b/, "Số điện thoại không hợp lệ")
      .required("Vui lòng nhập số điện thoại"),
    gender: Yup.string().required("Vui lòng chọn giới tính"),
    status: Yup.boolean(),
    password: Yup.string()
      .min(6, "Mật khẩu phải có ít nhất 6 ký tự")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
        "Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 số và 1 ký tự đặc biệt"
      )
      .required("Vui lòng nhập mật khẩu"),
    email: Yup.string()
      .required("Vui lòng nhập email")
      .email("Email không hợp lệ"),
    // role: Yup.string().required("Vui lòng chọn quyền hạn tài khoản"),
  });
  // ---------------------------------------------------------------------------------

  //--------------- modal edit account------------------------------------
  const [infoEditAccount, setInfoEditAccount] = useState({
    password: "",
    id: "",
    name: "",
    gender: "",
    birthday: "",
    phoneNumber: "",
    address: "",
    status: false,
  });
  const initialValuesE = {
    password: infoEditAccount.password,
    id: infoEditAccount.id,
    name: infoEditAccount.name,
    gender: infoEditAccount.gender,
    birthday: infoEditAccount.birthday,
    phoneNumber: infoEditAccount.phoneNumber,
    address: infoEditAccount.address,
    status: infoEditAccount.status,
  };
  const validationSchemaEdit = Yup.object().shape({
    password: Yup.string()
      .min(6, "Mật khẩu phải có ít nhất 6 ký tự")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
        "Mật khẩu mới phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 số và 1 ký tự đặc biệt"
      ),
    id: Yup.string().required("Không tìm thấy ID"),
    name: Yup.string().required("Vui lòng nhập họ tên"),
    gender: Yup.string().required("Vui lòng chọn giới tính"),
    birthday: Yup.date()
      .max(new Date(), "Ngày sinh không được lớn hơn ngày hiện tại")
      .required("Vui lòng chọn ngày sinh"),
    phoneNumber: Yup.string()
      .matches(/(03|05|07|08|09)+([0-9]{8})\b/, "Số điện thoại không hợp lệ")
      .required("Vui lòng nhập số điện thoại"),
    address: Yup.string(),
    status: Yup.boolean(),
  });
  // ---------------------------------------------------------------------------------

  useEffect(() => {
    axios
      .post("sunnyAdmin/v1/account/list", {
        status: dataStatus,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setDataAcount(response.data.response);
        setTotalPages(response.data.totalPage);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const test = () => {};

  return (
    <div>
      <div className="home-contentPage-title" onClick={test}>
        Quản lý tài khoản
      </div>
      <div className="contentM-option flex-div col-md-12">
        <div className="flex-div">
          <div className="contentM-wrapBtnAdd">
            <button className="account-btnAdd" onClick={handleShowAdd}>
              Thêm mới
            </button>
          </div>
          <div className="contentM-wrapBtnAdd">
            <FormSelect.Select
              onChange={handleSelectStatus}
              className="account-wrapSelectStatus font18"
            >
              <option value="">Tất cả trạng thái</option>
              <option value="ACTIVE">Sử dụng</option>
              <option value="INACTIVE">Không sử dụng</option>
            </FormSelect.Select>
          </div>
        </div>

        <div className="contentM-navbarRight">
          <div className="account-searchAddContainer center-lmh">
            <input
              type="text"
              placeholder="Tìm kiếm"
              name="search"
              className="account-inputSearch"
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyPress}
            />
            <button
              className="account-btnSearch"
              onClick={() => searchData(inputValue)}
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
      <div className="contentM-WrapTable table-responsive">
        <Table hover className="contentM-Table">
          <thead className="table-thead">
            <tr>
              <th style={{ textAlign: "center" }}>STT</th>
              <th>ID</th>
              <th>Họ & Tên</th>
              <th>Ngày sinh</th>
              <th>Địa chỉ</th>
              <th>Email</th>
              <th>Số điện thoại</th>
              <th>Giới tính</th>
              <th>Trạng thái</th>
              <th className="text-center">Tác vụ</th>
            </tr>
          </thead>
          <tbody className="table-tbody">
            {dataAccount ? (
              dataAccount.map((values, key) =>
                values.userId !== null ? (
                  <tr key={key}>
                    <td style={{ width: "1%", textAlign: "center" }}>
                      {key + 1 + itemsPerPage * (currentPage - 1)}
                    </td>
                    <td style={{ width: "7%" }}>{values.userId}</td>
                    <td style={{ width: "10%" }}>{values.fullName}</td>
                    <td style={{ width: "9%" }}>{values.birthDay}</td>
                    <td style={{ width: "25%" }}>{values.address}</td>
                    <td style={{ width: "13%" }}>{values.email}</td>
                    <td style={{ width: "10%" }}>{values.phoneNumber}</td>
                    <td style={{ width: "9%" }}>{values.gender}</td>
                    <td style={{ width: "9%" }}>
                      {values.status === "ACTIVE" ? "Sử dụng" : "Không sử dụng"}
                    </td>
                    <td style={{ width: "7%" }} className="text-center">
                      <FaPencilAlt
                        className="account-EditIcon"
                        onClick={() => handleShowEdit(values.userId)}
                      />
                    </td>
                  </tr>
                ) : null
              )
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>

      <div className="flex-div">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Pagination
            totalPages={parseInt(totalPages)} // tổng số trang
            currentPage={currentPage} //page hiện tại
            onPageChange={onPageChange} //sự kiện khi next trang
          />
        </div>
        <div className="col-md-2 flex-div">
          <ItemsPerPageSelect
            value={itemsPerPage} //Số item trong page
            onSelect={handleItemsPerPageSelect}
          />
        </div>
      </div>

      {/* ================================== Modal add================================== */}
      <Modal show={showModalAdd} onHide={handleCloseAdd} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Thêm mới tài khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaAdd}
            onSubmit={(values) => {
              const infoAdd = {
                userName: values.email,
                password: values.password,
                fullName: values.name,
                gender: values.gender,
                birthDay: values.birthday,
                phoneNumber: values.phoneNumber,
                address: values.address,
                email: values.email,
                status: values.status === true ? "ACTIVE" : "INACTIVE",
              };
              const bodyUpdate = JSON.stringify(infoAdd);
              // console.log(bodyUpdate);
              axios
                .post("sunnyAdmin/v1/account/create", bodyUpdate)
                .then((response) => {
                  Swal.fire({
                    title: "Thành công!",
                    text: "Thêm tài khoản thành công",
                    icon: "success",
                  }).then((result) => {
                    handleCloseAdd();
                    if (result.isConfirmed) {
                      // Thực thi hàm khi nhấn OK
                      axios
                        .post("sunnyAdmin/v1/account/list", {
                          status: dataStatus,
                          pageNumber: currentPage,
                          pageSize: itemsPerPage,
                        })
                        .then((response) => {
                          setDataAcount(response.data.response);
                          setTotalPages(response.data.totalPage);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  });
                })
                .catch((error) => {
                  console.log(error);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
                  });
                });
            }}
          >
            {({ values }) => (
              <Form>
                <div className="flex-div">
                  <div className="col-md-6">
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Họ & Tên: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="name"
                          className="account-AddInput"
                          id="name"
                          value={values.name}
                        />
                        <ErrorMessage
                          name="name"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4">Mật khẩu: </div>
                      <div className="col-md-8">
                        <Field
                          type="password"
                          name="password"
                          className="account-AddInput"
                          id="password"
                          value={values.password}
                        />
                        <ErrorMessage
                          name="password"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Email: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="email"
                          className="account-AddInput"
                          id="email"
                          value={values.email}
                        />
                        <ErrorMessage
                          name="email"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4">Giới tính: </div>
                      <div className="col-md-8">
                        <Field
                          as="select"
                          name="gender"
                          className="account-AddInput"
                          id="gender"
                        >
                          <option value="">-- Vui lòng chọn --</option>
                          <option value="Nam">Nam</option>
                          <option value="Nu">Nữ</option>
                        </Field>
                        <ErrorMessage
                          name="gender"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4">Ngày sinh: </div>
                      <div className="col-md-8">
                        <Field
                          type="date"
                          name="birthday"
                          className="account-AddInput"
                          id="birthday"
                          value={values.birthday}
                        />
                        <ErrorMessage
                          name="birthday"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Số điện thoại: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="phoneNumber"
                          className="account-AddInput"
                          id="phoneNumber"
                          value={values.phoneNumber}
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    {/* <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4">Gán quyền: </div>
                      <div className="col-md-8">
                        <Field
                          as="select"
                          name="role"
                          className="account-AddInput"
                          id="role"
                        >
                          <option value="">-- Vui lòng chọn --</option>
                          <option value="male">Admin</option>
                          <option value="female">User</option>
                        </Field>
                        <ErrorMessage
                          name="role"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div> */}
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Địa chỉ: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="address"
                          className="account-AddInput"
                          id="address"
                          value={values.address}
                        />
                        <ErrorMessage
                          name="address"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Trạng thái: </div>
                      <div className="center-lmh">
                        <Field
                          type="checkbox"
                          name="status"
                          className="account-AddInputCheckbox"
                        />
                        <ErrorMessage
                          name="status"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-div">
                  <div className="contentM-filter-Wrapbtn">
                    <button
                      type="submit"
                      className="contentM-btnAccept btn btn-success"
                    >
                      Thêm tài khoản
                    </button>
                    <button
                      onClick={handleCloseAdd}
                      className="contentM-btnCancel btn"
                    >
                      Huỷ
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ================================== Modal edit================================== */}
      <Modal show={showModalEdit} onHide={handleCloseEdit} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa thông tin tài khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValuesE}
            validationSchema={validationSchemaEdit}
            onSubmit={(values) => {
              const infoEdit = {
                userId: values.id,
                fullName: values.name,
                password: values.password,
                birthDay: values.birthday,
                phoneNumber: values.phoneNumber,
                gender: values.gender,
                address: values.address,
                status: values.status === true ? "ACTIVE" : "INACTIVE",
              };
              const bodyUpdate = JSON.stringify(infoEdit);
              axios
                .post("sunnyAdmin/v1/account/update", bodyUpdate)
                .then((response) => {
                  Swal.fire({
                    title: "Thành công!",
                    text: "Cập nhật dữ liệu thành công.",
                    icon: "success",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // Thực thi hàm khi nhấn OK
                      axios
                        .post("sunnyAdmin/v1/account/list", {
                          status: dataStatus,
                          pageNumber: currentPage,
                          pageSize: itemsPerPage,
                        })
                        .then((response) => {
                          setDataAcount(response.data.response);
                          setTotalPages(response.data.totalPage);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Đã có lỗi, vui lòng thử lại!",
                  });
                  console.log(error);
                });
              // console.log(bodyUpdate);
              handleCloseEdit();
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="flex-div">
                  <div className="col-md-6">
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Id: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="id"
                          className="account-AddInput"
                          id="id"
                          value={values.id}
                          disabled
                        />
                        <ErrorMessage
                          name="id"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Họ & Tên: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="name"
                          className="account-AddInput"
                          id="name"
                          value={values.name}
                        />
                        <ErrorMessage
                          name="name"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4">Mật khẩu: </div>
                      <div className="col-md-8">
                        <Field
                          type="password"
                          name="password"
                          className="account-AddInput"
                          id="password"
                          value={values.password}
                        />
                        <ErrorMessage
                          name="password"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4">Giới tính: </div>
                      <div className="col-md-8">
                        <Field
                          as="select"
                          name="gender"
                          className="account-AddInput"
                          id="gender"
                        >
                          <option value="Nam">Nam</option>
                          <option value="Nu">Nữ</option>
                        </Field>
                        <ErrorMessage
                          name="gender"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    {/* <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Email: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="email"
                          className="account-AddInput"
                          id="email"
                          value={values.email}
                        />
                        <ErrorMessage
                          name="email"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div> */}
                  </div>

                  <div className="col-md-6">
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4">Ngày sinh: </div>
                      <div className="col-md-8">
                        <Field
                          type="date"
                          name="birthday"
                          className="account-AddInput"
                          id="birthday"
                          value={values.birthday}
                        />
                        <ErrorMessage
                          name="birthday"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Số điện thoại: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="phoneNumber"
                          className="account-AddInput"
                          id="phoneNumber"
                          value={values.phoneNumber}
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Địa chỉ: </div>
                      <div className="col-md-8">
                        <Field
                          type="text"
                          name="address"
                          className="account-AddInput"
                          id="address"
                          value={values.address}
                        />
                        <ErrorMessage
                          name="address"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex-div account-wrapItemAdd">
                      <div className="col-md-4 center-lmh">Trạng thái: </div>
                      <div className="center-lmh">
                        <Field
                          type="checkbox"
                          name="status"
                          className="account-AddInputCheckbox"
                          onChange={() =>
                            setFieldValue("status", !values.status)
                          }
                        />
                        <ErrorMessage
                          name="status"
                          className="account-AddError"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-div">
                  <div className="contentM-filter-Wrapbtn">
                    <button
                      type="submit"
                      className="contentM-btnAccept btn btn-success"
                    >
                      Cập nhật
                    </button>
                    <button
                      type="button"
                      onClick={handleCloseEdit}
                      className="contentM-btnCancel btn"
                    >
                      Huỷ
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
