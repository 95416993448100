import React, { useState, useEffect } from "react";
import { FaSearch, FaPencilAlt } from "react-icons/fa";
import "../../assets/css/contentHome/SubCategory.css";
import Table from "react-bootstrap/Table";
import Pagination from "../shared/Pagination";
import ItemsPerPageSelect from "../shared/ItemsPerPageSelect";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Modal } from "react-bootstrap";
import { Form as FormSelect } from "react-bootstrap";
import axios, { setAuthToken } from "../../config/axiosConfig";
import Swal from "sweetalert2";

export default function SubCategory() {
  setAuthToken(localStorage.getItem("AuthorizationSunny"));
  const [inputValue, setInputValue] = useState("");
  //phân trang==============================================================
  const [currentPage, setCurrentPage] = useState(1); //page hiện tại
  const [itemsPerPage, setItemsPerPage] = useState(10); //số lượng phần tử trong một trang
  const [totalPages, setTotalPages] = useState(1); // tổng số page
  const [dataStatus, setDataStatus] = useState(""); //trang thai status
  const [dataSubCate, setDataSubCate] = useState(); //data sub category
  const [listCategory, setListCategory] = useState();

  // thực hiện ấn vào nút search---------------------------------------
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchData(event.target.value);
    }
  };
  const searchData = (inputValue) => {
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/subCategory/search", {
        status: dataStatus,
        keyword: inputValue,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setDataSubCate(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //xử lý chuyển trang
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    axios
      .post("sunnyAdmin/v1/subCategory/search", {
        status: dataStatus,
        keyword: inputValue,
        pageNumber: pageNumber,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setDataSubCate(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // xử lý thay đổi số phần tử trong trang
  const handleItemsPerPageSelect = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/subCategory/search", {
        status: dataStatus,
        keyword: inputValue,
        pageNumber: 1,
        pageSize: value,
      })
      .then((response) => {
        setDataSubCate(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fill lại bảng giá trị khi chọn trạng thái tài khoản--------------------------------------------------
  const handleSelectStatus = (event) => {
    setDataStatus(event.target.value);
    setCurrentPage(1);
    axios
      .post("sunnyAdmin/v1/subCategory/search", {
        status: event.target.value,
        keyword: inputValue,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        setDataSubCate(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //--------------- modal add account------------------------------------
  const [showModalAdd, setShowModalAdd] = useState(false);
  const handleCloseAdd = () => setShowModalAdd(false);
  const handleShowAdd = () => {
    setShowModalAdd(true);
  };
  const [infoSubCategory] = useState({
    subCategoryNameVn: "",
    subCategoryNameEn: "",
    categoryId: "",
    description: "",
    status: true,
  });
  const initialValues = {
    subCategoryNameVn: infoSubCategory.subCategoryNameVn,
    subCategoryNameEn: infoSubCategory.subCategoryNameEn,
    categoryId: infoSubCategory.categoryId,
    description: infoSubCategory.description,
    status: infoSubCategory.status,
  };
  const validationSchemaAdd = Yup.object().shape({
    subCategoryNameVn: Yup.string().required("Vui lòng nhập tên danh mục"),
    subCategoryNameEn: Yup.string().required("Vui lòng nhập tên danh mục"),
    categoryId: Yup.string().required("Không tìm thấy id danh mục"),
    description: Yup.string(),
    status: Yup.boolean(),
  });
  // ---------------------------------------------------------------------------------

  //--------------- modal edit account------------------------------------
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseEdit = () => setShowModalEdit(false);
  const [infoSubcateEdit, setInfoSubcateEdit] = useState({
    subCategoryId: "",
    subCategoryNameVn: "",
    subCategoryNameEn: "",
    categoryId: "",
    priority: "",
    description: "",
    status: false,
  });
  const handleShowEdit = (id) => {
    axios
      .post("sunnyAdmin/v1/subCategory/searchBySubCategoryId", {
        subCategoryId: id,
      })
      .then((response) => {
        setInfoSubcateEdit({
          subCategoryId: id,
          subCategoryNameVn: response.data.subCategoryVn,
          subCategoryNameEn: response.data.subCategoryEn,
          categoryId: response.data.categoryId,
          priority: response.data.priority === "ACTIVE" ? true : false,
          description: response.data.description,
          status: response.data.status === "ACTIVE" ? true : false,
        });
      })
      .then(() => {
        setShowModalEdit(true);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
        });
        console.log(error);
      });
  };
  const initialValuesEdit = {
    subCategoryId: infoSubcateEdit.subCategoryId,
    subCategoryNameVn: infoSubcateEdit.subCategoryNameVn,
    subCategoryNameEn: infoSubcateEdit.subCategoryNameEn,
    priority: infoSubcateEdit.priority,
    categoryId: infoSubcateEdit.categoryId,
    description: infoSubcateEdit.description ? infoSubcateEdit.description : "",
    status: infoSubcateEdit.status,
  };
  const validationSchemaEdit = Yup.object().shape({
    subCategoryId: Yup.string().required("Không tìm thấy id danh mục"),
    subCategoryNameVn: Yup.string().required("Vui lòng nhập tên danh mục"),
    subCategoryNameEn: Yup.string().required("Vui lòng nhập tên danh mục"),
    categoryId: Yup.string().required("Không tìm thấy id danh mục"),
    priority: Yup.boolean(),
    description: Yup.string(),
    status: Yup.boolean(),
  });
  // ---------------------------------------------------------------------------------

  useEffect(() => {
    axios
      .post("sunnyAdmin/v1/subCategory/list", {
        status: dataStatus,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((response) => {
        // console.log(response.data.response)
        setDataSubCate(response.data.response);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post("sunnyAdmin/v1/category/list", {})
      .then((response) => {
        setListCategory(response.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const test = () => {
    console.log(totalPages);
  };

  return (
    <div>
      <div className="home-contentPage-title" onClick={test}>
        Chi tiết danh mục
      </div>
      <div className="contentM-option flex-div col-md-12">
        <div className="flex-div">
          <div className="contentM-wrapBtnAdd">
            <button className="account-btnAdd" onClick={handleShowAdd}>
              Thêm mới
            </button>
          </div>
          <div className="contentM-wrapBtnAdd">
            <FormSelect.Select
              onChange={handleSelectStatus}
              className="account-wrapSelectStatus font18"
            >
              <option value="">Tất cả trạng thái</option>
              <option value="ACTIVE">Sử dụng</option>
              <option value="INACTIVE">Không sử dụng</option>
            </FormSelect.Select>
          </div>
        </div>
        <div className="contentM-navbarRight">
          <div className="account-searchAddContainer center-lmh">
            <input
              type="text"
              placeholder="Tìm kiếm"
              name="search"
              className="account-inputSearch"
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyPress}
            />
            <button
              className="account-btnSearch"
              onClick={() => searchData(inputValue)}
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
      <div className="contentM-WrapTable table-responsive">
        <Table hover className="contentM-Table">
          <thead className="table-thead">
            <tr>
              <th style={{ textAlign: "center" }}>STT</th>
              <th>Danh mục</th>
              <th>Tên Tiếng Việt</th>
              <th>Tên Tiếng Anh</th>
              <th>Mô tả</th>
              <th>Trạng thái</th>
              <th className="text-center">Tác vụ</th>
            </tr>
          </thead>
          <tbody className="table-tbody">
            {dataSubCate ? (
              dataSubCate.map((value, key) => (
                <tr key={key}>
                  <td style={{ width: "1%", textAlign: "center" }}>
                    {key + 1 + itemsPerPage * (currentPage - 1)}
                  </td>
                  <td style={{ width: "10%" }}>
                    {value.categoryNameVn
                      ? value.categoryNameVn.toUpperCase()
                      : "NULL"}
                  </td>
                  <td style={{ width: "23%" }}>{value.subCategoryVn}</td>
                  <td style={{ width: "23%" }}>{value.subCategoryEn}</td>
                  <td style={{ width: "25%" }}>{value.description}</td>
                  <td style={{ width: "10%" }}>
                    {value.status === "ACTIVE" ? "Sử dụng" : "Không sử dụng"}
                  </td>
                  <td style={{ width: "10%" }} className="text-center">
                    <FaPencilAlt
                      className="account-EditIcon"
                      onClick={() => handleShowEdit(value.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>

      <div className="flex-div">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Pagination
            totalPages={parseInt(totalPages)} // tổng số trang
            currentPage={currentPage} //page hiện tại
            onPageChange={onPageChange} //sự kiện khi next trang
          />
        </div>
        <div className="col-md-2 flex-div">
          <ItemsPerPageSelect
            value={itemsPerPage} //Số item trong page
            onSelect={handleItemsPerPageSelect}
          />
        </div>
      </div>

      {/* ================================== Modal add================================== */}
      <Modal show={showModalAdd} onHide={handleCloseAdd} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Thêm mới</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaAdd}
            onSubmit={(values) => {
              const infoAdd = {
                subCategoryId: values.subCategoryId,
                subCategoryNameVn: values.subCategoryNameVn,
                subCategoryNameEn: values.subCategoryNameEn,
                categoryId: values.categoryId,
                description: values.description,
                status: values.status === true ? "ACTIVE" : "INACTIVE",
              };
              const bodyAdd = JSON.stringify(infoAdd);
              axios
                .post("sunnyAdmin/v1/subCategory/create", bodyAdd)
                .then((response) => {
                  if (response.data.status === "INVALID") {
                    Swal.fire({
                      icon: "warning",
                      title: "Oops...",
                      text: "Tên danh mục đã tồn tại, vui lòng thử lại! ",
                    });
                  } else if (response.data.status === "SUCCESS") {
                    Swal.fire({
                      title: "Thành công!",
                      text: "Thêm mới danh mục thành công",
                      icon: "success",
                    }).then((result) => {
                      handleCloseAdd();
                      if (result.isConfirmed) {
                        // Thực thi hàm khi nhấn OK
                        axios
                          .post("sunnyAdmin/v1/subCategory/list", {
                            status: dataStatus,
                            pageNumber: 1,
                            pageSize: itemsPerPage,
                          })
                          .then((response) => {
                            setDataSubCate(response.data.response);
                            setTotalPages(response.data.totalPages);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
                  });
                });
            }}
          >
            {({ values }) => (
              <Form>
                <div className="category-wrapItemAdd-T">
                  <div className="category-ItemAddTitle">Danh mục: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      as="select"
                      name="categoryId"
                      className="account-AddInput"
                      id="categoryId"
                    >
                      <option value="">--Vui lòng chọn danh mục--</option>
                      {listCategory ? (
                        listCategory.map((values, key) => (
                          <option
                            key={key}
                            value={values.id}
                            className={`${
                              values.id ===
                              "ebb9e571-9f7a-4200-97f4-eaf575c2ddc6"
                                ? "subCate-modalAddInfo"
                                : ""
                            }`}
                          >
                            {values.categoryVn.toUpperCase()}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Field>
                    <ErrorMessage
                      name="categoryId"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Tên Tiếng Việt: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      type="text"
                      name="subCategoryNameVn"
                      className="category-AddInput"
                      id="subCategoryNameVn"
                      value={values.subCategoryNameVn}
                    />
                    <ErrorMessage
                      name="subCategoryNameVn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Tên Tiếng Anh: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      type="text"
                      name="subCategoryNameEn"
                      className="category-AddInput"
                      id="subCategoryNameEn"
                      value={values.subCategoryNameEn}
                    />
                    <ErrorMessage
                      name="subCategoryNameEn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Mô tả: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      type="text"
                      name="description"
                      className="category-AddInput"
                      id="description"
                      value={values.description}
                    />
                    <ErrorMessage
                      name="description"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Trạng thái: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      type="checkbox"
                      name="status"
                      className="subCategory-checkbox"
                    />
                    <ErrorMessage
                      name="status"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>

                <div className="category-filter-Wrapbtn">
                  <button
                    type="submit"
                    className="category-btnAccept btn btn-success"
                  >
                    Thêm danh mục
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseAdd}
                    className="category-btnCancel btn"
                  >
                    Huỷ
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ================================== Modal edit================================== */}
      <Modal show={showModalEdit} onHide={handleCloseEdit} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValuesEdit}
            validationSchema={validationSchemaEdit}
            onSubmit={(values) => {
              const infoEdit = {
                subCategoryId: values.subCategoryId,
                subCategoryNameVn: values.subCategoryNameVn,
                subCategoryNameEn: values.subCategoryNameEn,
                categoryId: values.categoryId,
                priority: values.priority === true ? "ACTIVE" : "INACTIVE",
                description: values.description,
                status: values.status === true ? "ACTIVE" : "INACTIVE",
              };
              const bodyUpdate = JSON.stringify(infoEdit);
              // console.log(bodyUpdate);
              axios
                .post("sunnyAdmin/v1/subCategory/update", bodyUpdate)
                .then((response) => {
                  Swal.fire({
                    title: "Thành công!",
                    text: "Cập nhật danh mục thành công",
                    icon: "success",
                  }).then((result) => {
                    handleCloseEdit();
                    if (result.isConfirmed) {
                      // Thực thi hàm khi nhấn OK
                      axios
                        .post("sunnyAdmin/v1/subCategory/search", {
                          status: dataStatus,
                          keyword: inputValue,
                          pageNumber: currentPage,
                          pageSize: itemsPerPage,
                        })
                        .then((response) => {
                          setDataSubCate(response.data.response);
                          setTotalPages(response.data.totalPages);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  });
                })
                .catch((error) => {
                  console.log(error);
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Đã có lỗi xảy ra, vui lòng thử lại! ",
                  });
                });
            }}
          >
            {({ values }) => (
              <Form>
                <div className="category-wrapItemAdd-T">
                  <div className="category-ItemAddTitle">Danh mục: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      as="select"
                      name="categoryId"
                      className="account-AddInput"
                      id="categoryId"
                    >
                      {listCategory ? (
                        listCategory.map((values, key) => (
                          <option key={key} value={values.id}>
                            {values.categoryVn.toUpperCase()}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Field>
                    <ErrorMessage
                      name="categoryId"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Tên Tiếng Việt: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      type="text"
                      name="subCategoryNameVn"
                      className="category-AddInput"
                      id="subCategoryNameVn"
                      value={values.subCategoryNameVn}
                    />
                    <ErrorMessage
                      name="subCategoryNameVn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Tên Tiếng Anh: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      type="text"
                      name="subCategoryNameEn"
                      className="category-AddInput"
                      id="subCategoryNameEn"
                      value={values.subCategoryNameEn}
                    />
                    <ErrorMessage
                      name="subCategoryNameEn"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="category-wrapItemAdd">
                  <div className="category-ItemAddTitle">Mô tả: </div>
                  <div className="subCate-editModalInput">
                    <Field
                      type="text"
                      name="description"
                      className="category-AddInput"
                      id="description"
                      value={values.description}
                    />
                    <ErrorMessage
                      name="description"
                      className="account-AddError"
                      component="div"
                    />
                  </div>
                </div>
                <div className="flex-div">
                  <div className="category-wrapItemAdd col-md-6">
                    <div className="category-ItemAddTitle">Trạng thái: </div>
                    <div className="subCate-editModalInput">
                      <Field
                        type="checkbox"
                        name="status"
                        className="subCategory-checkbox"
                      />
                      <ErrorMessage
                        name="status"
                        className="account-AddError"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="category-wrapItemAdd col-md-6">
                    <div className="category-ItemAddTitle">Ghim: </div>
                    <div className="subCate-editModalInput">
                      <Field
                        type="checkbox"
                        name="priority"
                        className="subCategory-checkbox"
                      />
                      <ErrorMessage
                        name="priority"
                        className="account-AddError"
                        component="div"
                      />
                    </div>
                  </div>
                </div>

                <div className="category-filter-Wrapbtn">
                  <button
                    type="submit"
                    className="category-btnAccept btn btn-success"
                  >
                    Cập nhật
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseEdit}
                    className="category-btnCancel btn"
                  >
                    Huỷ
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
