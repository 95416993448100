import axios from "axios";

// const url = "http://"+process.env.REACT_APP_SUNNY_ADMIN_FE_IP+':'+process.env.REACT_APP_SUNNY_ADMIN_FE_PORT;
const url = process.env.REACT_APP_SUNNY_ADMIN_FE_URL;
console.log(url);
const axiosConfig = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization, X-Requested-With, Accept",
  },
});
// Hàm này truyền token vào header của request
export function setAuthToken(token) {
  if (token) {
    axiosConfig.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosConfig.defaults.headers.common["Authorization"];
  }
}

export default axiosConfig;
